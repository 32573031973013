export const PAYMENTS_URL = '/dashboard/transactions/payments'
export const TRANSACTIONS_URL = '/dashboard/transactions'
export const TRADE_ACCOUNTS_URL = '/dashboard/trade-accounts'
export const TRADE_ACCOUNT_URL = '/dashboard/trade-accounts/:tradeAccountId'
export const TRANSACTION_RECEIVABLE =
  '/dashboard/transactions/receivable/:transactionId'
export const TRANSACTION_REFUND =
  '/dashboard/transactions/refund/:transactionId'
export const TRANSACTION_PAYABLE =
  '/dashboard/transactions/payable/:transactionId'
export const TRANSACTION_RETURN =
  '/dashboard/transactions/return/:transactionId'
export const TRANSACTION_VENDOR_RETURN =
  '/dashboard/transactions/vendorReturn/:transactionId'
export const TRANSACTION_CHARGEBACK =
  '/dashboard/transactions/chargeback/:transactionId'
export const TRANSACTION_ADVANCE =
  '/dashboard/transactions/advance/:transactionId'

export const DEPOSIT_CHECK_URL = `${PAYMENTS_URL}/deposit`
export const PAYMENTS_SLUG = `${PAYMENTS_URL}/:paymentId`
export const REFUNDS_URL = '/dashboard/transactions/refunds'
export const REFUNDS_SLUG = `${REFUNDS_URL}/:refundId`
export const PAYOUTS_URL = '/dashboard/payouts'
export const SETTLEMENTS_URL = '/dashboard/settlements'
export const PAYOUTS_SLUG = `${PAYOUTS_URL}/:payoutId`
export const SETTLEMENTS_SLUG = `${SETTLEMENTS_URL}/:payoutId`
export const CHECKOUTS_URL = '/dashboard/payment-links'
export const AP_SEND_MONEY_URL_V2 = `/send-money`
export const PAYMENT_LINK_BULK = '/dashboard/payment-links/bulk'
export const GET_PAID_URL = '/dashboard/get-paid'
export const QUESTION_FORM_URL = '/dashboard/get-paid/:questionFormId'
export const NICKEL_PLANS_URL = '/dashboard/nickel-plans'
export const NEW_CLIENT_URL = '/newclient'
export const HUBSPOT_AUTHENTICATED_URL = '/dashboard/hubspot-authenticated/'
export const HUBSPOT_CREATE_PAYMENT_LINK_URL = '/hubspot-create-payment-link'
export const AP_URL = '/dashboard/accounts-payable'
export const PAY_VENDORS_BILLS_URL = `${AP_URL}/bills`
export const PAY_VENDORS_APPROVALS_URL = `${AP_URL}/approvals`
export const REGISTRATION_SELF_SERVE_URL = '/register'

export const fetchPortalURL = (subdomain: string = 'nickel') => {
  if (import.meta.env.MODE === 'development') {
    return `http://${subdomain}.localhost:3001`
  } else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'staging') {
    return `https://${subdomain}.staging.nickelpayments.com`
  } else if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'demo') {
    return `https://${subdomain}.demo.nickelpayments.com`
  } else {
    return `https://${subdomain}.nickelpayments.com`
  }
}
