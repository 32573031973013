import { useMutation, useQuery } from '@apollo/client'
import { Box, useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import { useNavigate } from 'react-router-dom'
import { TableCellProps, TableV2 } from 'ui'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { getPaymentMethodPayoutIdentifier } from '../../lib/utils'
import {
  ApprovalsDocument,
  ApprovalsQuery,
  ApprovalsQueryVariables,
  ApprovalStatus,
  ChangeApprovalStatusDocument,
  UserRole,
  VendorPayoutMethod,
} from '../../operations-types'
import { User } from '../../types'

import { useAuth } from '../../lib/auth'

type ApprovalsTableProps = {
  queryOpts: ApprovalsQueryVariables
  onPage: (page: number) => void
}

type Approval = NonNullable<
  NonNullable<ApprovalsQuery['approvals']>['approvals']
>[0]

type ApprovalTableData = {
  id: string
  vendor: string
  reference: {
    source: string
    label: string
  }
  createdAt: number
  status: {
    status: string
    label: string
  }
  paymentMethod: {
    name: string
    type: string
    identifier: string
  }
  payoutMethod: {
    name: string
    type: string
    identifier: string
  }
  paymentAmount: number
  showAction: string
  billPaymentId?: string
}

const APPROVAL_STATUS_MAP: Record<ApprovalStatus, string> = {
  [ApprovalStatus.Pending]: 'info',
  [ApprovalStatus.Approved]: 'success',
  [ApprovalStatus.Rejected]: 'warning',
}

const APPROVAL_LABEL_MAP: Record<ApprovalStatus, string> = {
  [ApprovalStatus.Pending]: 'Pending',
  [ApprovalStatus.Approved]: 'Approved',
  [ApprovalStatus.Rejected]: 'Rejected',
}

const getShowAction = (
  status: ApprovalStatus,
  loggedInUser: User,
  isAccountant: boolean,
  approverId?: string,
  approverRole?: UserRole,
) => {
  // Also check who is the appropriate approver
  if (status === ApprovalStatus.Approved) {
    return 'PAYMENT'
  }

  if (status === ApprovalStatus.Pending) {
    if (isAccountant) {
      return ''
    }
    if (loggedInUser.id === approverId) {
      return 'ACTION'
    }

    if (loggedInUser.role === approverRole || loggedInUser.role === 'ADMIN') {
      return 'ACTION'
    }
  }

  return ''
}

export function getVendorPayoutMethodIdentifier(
  vendorPayoutMethod: VendorPayoutMethod,
) {
  if (!vendorPayoutMethod) {
    return 'Unknown'
  }

  if (vendorPayoutMethod.type === 'CHECK') {
    return `${vendorPayoutMethod.city}, ${vendorPayoutMethod.state}` || ''
  }
  return `${vendorPayoutMethod.bankName} ·· ${
    vendorPayoutMethod.accountNumber?.slice(-2) || ''
  }`
}

function transformApprovalIntoTableData(
  approval: Approval[],
  loggedInUser: User,
  isAccountant: boolean,
): ApprovalTableData[] {
  return approval.map((approval) => ({
    id: approval.id,
    vendor: approval.billPayable?.vendor?.name || 'N/A',
    reference: {
      source: approval?.billPayable?.bills?.at(0)?.billSource || 'N/A',
      label: approval.billPayable?.invoiceNumber || 'N/A',
    },
    createdAt: parseInt(approval.createdAt || '') || 0,
    paymentMethod: getPaymentMethodPayoutIdentifier(approval.paymentMethod!),
    payoutMethod: {
      type:
        approval?.vendorPayoutMethod?.type === 'ACH'
          ? 'bankAccount'
          : 'checkDeposit',
      identifier: getVendorPayoutMethodIdentifier(
        approval?.vendorPayoutMethod!,
      ),
      name: approval?.billPayable?.vendor?.name || '',
    },
    paymentAmount: currency(approval.submittedAmountInCents || 0, {
      fromCents: true,
    }).value,
    //TODO(claudio.wilson): Have to change this when we support more than just a single approval mechanism
    showAction: getShowAction(
      approval.status!,
      loggedInUser,
      isAccountant,
      approval.approvalPolicy?.approvalSteps?.at(0)?.approver?.id,
      approval.approvalPolicy?.approvalSteps?.at(0)?.approverRole ?? undefined,
    ),
    status: {
      status: APPROVAL_STATUS_MAP[approval.status!],
      label: APPROVAL_LABEL_MAP[approval.status!],
    },
    billPaymentId: approval.billPayable?.billPayments?.[0]?.id,
  }))
}

export function ApprovalsTable({ queryOpts, onPage }: ApprovalsTableProps) {
  const { data, loading } = useQuery(ApprovalsDocument, {
    variables: queryOpts,
  })

  const user = useDashboardOutletContext()
  const { getAccountantUserId } = useAuth()

  const accountantIsLoggedIn = !!getAccountantUserId()

  const navigate = useNavigate()
  const [updateApproval, { loading: approvalLoading }] = useMutation(
    ChangeApprovalStatusDocument,
    {
      refetchQueries: ['approvals', 'paymentActivities'],
      onCompleted: (data) => {
        if (
          data?.changeApprovalStatus?.error?.message ||
          !data?.changeApprovalStatus?.approval
        ) {
          toaster({
            title: 'Error',
            description:
              data?.changeApprovalStatus?.error?.message || 'An error occurred',
            status: 'error',
            duration: 5000,
          })
          return
        }

        const approval = data.changeApprovalStatus.approval

        if (approval.status === ApprovalStatus.Approved) {
          toaster({
            title: 'Success',
            description: `${approval.billPayable?.vendor
              ?.name} will be paid ${currency(
              approval?.submittedAmountInCents || 0,
              {
                fromCents: true,
              },
            ).format()}.`,
            status: 'success',
            duration: 5000,
          })
        } else {
          toaster({
            title: 'Success',
            description: `Payment to ${approval.billPayable?.vendor?.name} has been denied.`,
            status: 'success',
            duration: 5000,
          })
        }
      },
    },
  )

  const totalPages = Math.round(
    (data?.approvals?.totalResults || 0) / (queryOpts.pageSize || 20),
  )

  const toaster = useToast()

  const table = (
    <TableV2
      loading={loading || approvalLoading}
      rowSize="large"
      cellSize="large"
      headers={
        [
          { label: 'Vendor', keyName: 'vendor', type: 'description', grow: 1 },
          {
            type: 'billId',
            label: 'Bill / Reason for Payment',
            keyName: 'reference',
            width: 'large',
          },
          queryOpts?.approvalStatus !== ApprovalStatus.Pending
            ? {
                type: 'status',
                label: 'Status',
                keyName: 'status',
              }
            : null,
          {
            type: 'date',
            label: 'Submitted On',
            keyName: 'createdAt',
          },
          {
            type: 'methodWithName',
            keyName: 'paymentMethod',
            label: 'Payment Method',
            grow: 1,
          },
          {
            type: 'methodWithName',
            keyName: 'payoutMethod',
            label: 'Delivery Method',
            grow: 1,
          },
          {
            type: 'currency',
            keyName: 'paymentAmount',
            label: 'Payment',
          },
          {
            type: 'approveDeny',
            label: 'Action',
            keyName: 'showAction',
            width: 'large',
            onClick: (
              keyName: string,
              approval: ApprovalTableData,
              newStatus: string,
            ) => {
              if (newStatus === 'APPROVED') {
                updateApproval({
                  variables: {
                    approvalId: approval.id,
                    status: ApprovalStatus.Approved,
                  },
                })
              } else if (newStatus === 'REJECTED') {
                updateApproval({
                  variables: {
                    approvalId: approval.id,
                    status: ApprovalStatus.Rejected,
                  },
                })
              } else {
                if (approval.billPaymentId) {
                  navigate(
                    `/dashboard/transactions/payable/${approval.billPaymentId}`,
                  )
                }
              }
            },
          },
        ].filter(Boolean) as TableCellProps<object>[]
      }
      data={transformApprovalIntoTableData(
        data?.approvals?.approvals || [],
        user,
        accountantIsLoggedIn,
      )}
      page={queryOpts.page || 1}
      perPage={queryOpts.pageSize || 10}
      totalPages={totalPages || 1}
      onPage={onPage}
    />
  )

  return (
    <Box w="100%" pt="4">
      {table}
    </Box>
  )
}
