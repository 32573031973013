import { Button, Flex, Card } from '@chakra-ui/react'
import { useState } from 'react'
import {
  FieldTooltip,
  Form,
  FormikValidatingInput,
  ValidatingInput,
  websiteRegex,
} from 'ui'
import { AddClientLayout } from './AddClientFlow'
import * as yup from 'yup'
import { useMutation } from '@apollo/client'
import {
  SelfDocument,
  UpdateOnboardingInformationDocument,
} from '../../operations-types'
import { AddClientStates, ClientOrg } from './addClientStore'
import { QuickbooksAuthentication } from '../erp/QuickbooksAuthentication'

type AccoutingSyncProps = {
  clientOrg: ClientOrg
  setCurrentPage: (page: AddClientStates) => void
}

type AccountingSyncStates = 'auth' | 'manual' | 'authSuccess'

const ManualCompanyInformation = yup.object({
  website: yup
    .string()
    .matches(
      websiteRegex,
      'Please enter a valid website URL, i.e.: www.example.com',
    ),
  taxIdNumber: yup.string().required('Tax ID number is required'),
})
type ManualOnboardingProps = {
  onCompleted: () => void
}

function ManualOnboarding({ onCompleted }: ManualOnboardingProps) {
  const [updateOnboardingInfo, { loading }] = useMutation(
    UpdateOnboardingInformationDocument,
    {
      onCompleted: (data) => {
        if (data.updateOnboardingInformation?.error?.message) {
          console.error(data.updateOnboardingInformation.error.message)
          return
        }
        onCompleted()
      },
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  return (
    <Form
      validationSchema={ManualCompanyInformation}
      initialValues={{
        website: '',
        taxIdNumber: '',
        companyAddress: {
          zipCode: '',
          state: '',
          streetAddress: '',
          city: '',
        },
      }}
    >
      {(formik) => {
        return (
          <Card py={8} px={8}>
            <Flex flexDirection="column" gap={6}>
              <FormikValidatingInput
                fieldName="website"
                fontSize="sm"
                label="Website"
                placeholder="https://www.website.com"
              />
              <ValidatingInput
                id="taxIdNumber"
                fontSize="sm"
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.taxIdNumber}
                validated={formik.touched.taxIdNumber}
                label="Business Identifier"
                placeholder="EIN, SSN, or Tax ID"
                value={formik.values.taxIdNumber}
                error={formik.errors.taxIdNumber}
                popover={<FieldTooltip text="ie: Tax ID#, SSN # or EIN." />}
              />
              <Button
                isLoading={loading}
                onClick={() => {
                  formik.validateForm()
                  formik.setTouched({
                    website: true,
                    taxIdNumber: true,
                    companyAddress: {
                      zipCode: true,
                      state: true,
                      streetAddress: true,
                      city: true,
                    },
                  })

                  if (formik.isValid) {
                    updateOnboardingInfo({
                      variables: {
                        onboardingInfo: JSON.stringify({
                          ...formik.values,
                          termsAccepted: true,
                          applicationSubmitted: true,
                        }),
                      },
                    })
                  }
                }}
              >
                Onboard Client
              </Button>
            </Flex>
          </Card>
        )
      }}
    </Form>
  )
}

export function AccountingSync({
  clientOrg,
  setCurrentPage,
}: AccoutingSyncProps) {
  const [currentPage, setAccountingPage] =
    useState<AccountingSyncStates>('auth')
  const [noButtonDisabled, setNoButtonDisabled] = useState(false)
  const [updateOnboardingInfo] = useMutation(
    UpdateOnboardingInformationDocument,
    {
      refetchQueries: [
        {
          query: SelfDocument,
        },
      ],
    },
  )

  const onCompleted = async () => {
    await updateOnboardingInfo({
      variables: {
        onboardingInfo: JSON.stringify({
          termsAccepted: true,
          applicationSubmitted: true,
        }),
      },
    })
  }

  const statesToComponent: Record<AccountingSyncStates, React.ReactNode> = {
    auth: (
      <AddClientLayout
        title={`Does ${clientOrg.name} use QuickBooks Online?`}
        subtitle="Quickly connect to sync their bills, invoices and vendors."
        currentPage="accounting"
        setPage={setCurrentPage}
      >
        <Flex width="540px" flexDirection="column" gap={6}>
          <QuickbooksAuthentication
            onClick={() => setNoButtonDisabled(true)}
            onCompleted={() => {
              onCompleted()
              setAccountingPage('authSuccess')
            }}
          />
          <Button
            variant="outline"
            onClick={() => setAccountingPage('manual')}
            isDisabled={noButtonDisabled}
          >
            No
          </Button>
        </Flex>
      </AddClientLayout>
    ),
    manual: (
      <AddClientLayout
        title={`Manually add ${clientOrg.name}`}
        subtitle="Tell us some information about this business to onboard them."
        currentPage="accounting"
        setPage={setCurrentPage}
      >
        <ManualOnboarding
          onCompleted={async () => {
            setCurrentPage('bankAccount')
          }}
        />
      </AddClientLayout>
    ),
    authSuccess: (
      <AddClientLayout
        title="Success!"
        subtitle={`You've successfully synced ${clientOrg.name}'s QuickBooks data to Nickel.`}
        currentPage="accounting"
        setPage={setCurrentPage}
      >
        <Button onClick={() => setCurrentPage('bankAccount')}>Next</Button>
      </AddClientLayout>
    ),
  }

  return <>{statesToComponent[currentPage]}</>
}
