import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  ACHForm,
  AddressInput,
  Button,
  Column,
  Form,
  Row,
  SelectInput,
  US_STATES,
} from 'ui'
import {
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorPayoutMethodDocument,
  Vendor,
  VendorPayoutMethod,
  VendorsDocument,
} from '../../../operations-types'
import { useEffect, useState } from 'react'
import {
  RecipientDetailsValidationSchema,
  RecipientDetailsValidationSchemaType,
} from './SendPaymentRouter'
import { FormSection } from './Base'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  Box,
  HStack,
  Text,
  useToast,
  VStack,
  Icon,
  Divider,
  IconButton,
  Spinner,
} from '@chakra-ui/react'
import { BuildingLibraryIcon, PencilIcon } from '@heroicons/react/24/outline'
import { MoneyCheck } from 'ui/src/components/Icons/MoneyCheck'

type VendorPayoutDetailsProps = {
  vendorId: string
  vendorName: string
  vendorAchPayoutMethod?: VendorPayoutMethod
  vendorCheckPayoutMethod?: VendorPayoutMethod
  label?: boolean
  setSelectedVendor?: (vendor: Vendor | null) => void
  onVendor?: (vendor: Vendor | null) => void
}

export default function PaymentDetails({
  paymentVia,
  setEditVendorPayout,
  vendorAchPayoutMethod,
  vendorCheckPayoutMethod,
  isLoading,
}: {
  paymentVia: string
  setEditVendorPayout: (value: boolean) => void
  vendorAchPayoutMethod: VendorPayoutMethod | undefined
  vendorCheckPayoutMethod: VendorPayoutMethod | undefined
  isLoading: boolean
}) {
  const isAch = paymentVia === 'ACH'
  const payoutMethodDetails =
    paymentVia === 'ACH' ? vendorAchPayoutMethod : vendorCheckPayoutMethod

  return (
    <VStack spacing={4} w="100%" alignItems="start">
      {payoutMethodDetails && !isLoading ? (
        <Box borderRadius="md" w="100%" mt="-1">
          <HStack justify="space-between" mb={2}>
            <HStack>
              {isAch ? (
                <>
                  <Text fontSize="md" fontWeight="medium" color="gray.700">
                    ACH/eCheck
                  </Text>
                  <Icon as={BuildingLibraryIcon} w={5} h={5} color="gray.600" />
                </>
              ) : (
                <>
                  <Text fontSize="md" fontWeight="medium" color="gray.700">
                    Physical Check
                  </Text>
                  <MoneyCheck width={20} height={20} />
                </>
              )}
            </HStack>
            <IconButton
              aria-label="Edit Vendor Payout Method"
              icon={<Icon as={PencilIcon} />}
              variant="outline"
              size="sm"
              onClick={() => setEditVendorPayout(true)}
            >
              Edit
            </IconButton>
          </HStack>
          <Divider mb={2} />
          {isAch ? (
            <VStack spacing="0" alignItems="start">
              <Text fontSize="sm" color="gray.800">
                {payoutMethodDetails.bankName ||
                  `Routing: ${payoutMethodDetails.routingNumber}`}
              </Text>
              <Text fontSize="sm">
                Account #: {payoutMethodDetails.accountNumber}
              </Text>
            </VStack>
          ) : (
            <Text fontSize="sm" color="gray.800">
              {payoutMethodDetails.street}, {payoutMethodDetails.city},{' '}
              {payoutMethodDetails.state} {payoutMethodDetails.zip}
            </Text>
          )}
        </Box>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <Button
          alignSelf="flex-start"
          label="Add Delivery Method"
          onClick={() => setEditVendorPayout(true)}
          size="xs"
        />
      )}
    </VStack>
  )
}

export function VendorPayoutDetails({
  vendorId,
  vendorName,
  vendorAchPayoutMethod,
  vendorCheckPayoutMethod,
  label = true,
  setSelectedVendor,
  onVendor,
}: VendorPayoutDetailsProps) {
  const [updateVendorPayoutMethod, { loading: vendorPayoutLoading }] =
    useMutation(UpdateVendorPayoutMethodDocument)

  const [updateVendorCheckPayoutMethod, { loading: updateCheckLoading }] =
    useMutation(UpdateVendorCheckPayoutMethodDocument)

  const [search, { loading: bankRoutingLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)
  const [editVendorPayout, setEditVendorPayout] = useState(false)
  const toast = useToast()

  const { refetch } = useQuery(VendorsDocument)

  const [paymentVia, setPaymentVia] = useState(
    vendorAchPayoutMethod ? 'ACH' : vendorCheckPayoutMethod ? 'check' : '',
  )

  useEffect(() => {
    const fetchSelectedVendor = async () => {
      if (vendorName && onVendor) {
        const selectedVendorData = await refetch({
          vendorName: vendorName,
        })
        onVendor(selectedVendorData.data?.vendors?.vendors?.[0] || null)
      }
      if (vendorPayoutLoading === true) {
        setPaymentVia('ACH')
      } else if (updateCheckLoading === true) {
        setPaymentVia('check')
      }
    }
    fetchSelectedVendor()
  }, [vendorPayoutLoading, updateCheckLoading])

  return (
    <Form
      {...{
        className: 'w-full',
        validationSchema: RecipientDetailsValidationSchema,
        initialValues: {
          vendorName: vendorName,
          achDetails: {
            accountNumber: vendorAchPayoutMethod?.accountNumber || '',
            bankName: vendorAchPayoutMethod?.bankName || '',
            bankRoutingNumberLoading: vendorAchPayoutMethod ? true : false,
            routingNumber: vendorAchPayoutMethod?.routingNumber || '',
            confirmedAccountNumber: vendorAchPayoutMethod?.accountNumber || '',
            accountType: 'checking',
            recipientAddress: {
              streetAddress: vendorCheckPayoutMethod?.street || '',
              city: vendorCheckPayoutMethod?.city || '',
              state:
                US_STATES.states.find(
                  (x) =>
                    x.abbreviation === vendorCheckPayoutMethod?.state ||
                    x.name === vendorCheckPayoutMethod?.state,
                )?.name || '',
              zipCode: vendorCheckPayoutMethod?.zip?.split('-')?.at(0) || '',
            },
          },
          paymentVia: paymentVia,
        } as RecipientDetailsValidationSchemaType,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: async (values, props) => {
          if (values.paymentVia === 'ACH') {
            const vendorPayoutMethod = await updateVendorPayoutMethod({
              variables: {
                vendorId: vendorId || '',
                accountNumber: values.achDetails.accountNumber || '',
                routingNumber: values.achDetails.routingNumber || '',
                accountType: values.achDetails.accountType || '',
              },
            })

            if (vendorPayoutMethod.data?.updateVendorPayoutMethod?.error) {
              toast({
                status: 'error',
                description:
                  vendorPayoutMethod.data?.updateVendorPayoutMethod?.error
                    .message,
                duration: 9000,
                isClosable: true,
              })
              return
            }
          } else if (values.paymentVia === 'check') {
            const vendorPayoutMethod = await updateVendorCheckPayoutMethod({
              variables: {
                vendorId: vendorId || '',
                street:
                  values.achDetails?.recipientAddress?.streetAddress || '',
                city: values.achDetails?.recipientAddress?.city || '',
                state: values.achDetails.recipientAddress.state || '',
                zip: values.achDetails.recipientAddress.zipCode || '',
              },
            })

            if (vendorPayoutMethod.data?.updateVendorCheckPayoutMethod?.error) {
              toast({
                status: 'error',
                description:
                  vendorPayoutMethod.data?.updateVendorCheckPayoutMethod?.error
                    .message,
                duration: 9000,
                isClosable: true,
              })
              return
            }
          }

          toast({
            status: 'success',
            title: 'Success',
            description: 'Vendor delivery method updated!',
            duration: 9000,
            isClosable: true,
          })
        },
      }}
    >
      {(props) => (
        <Column wGrow gap="medium">
          <Row y="center">
            {label && (
              <div className="text-xl font-semibold">Vendor Details</div>
            )}
          </Row>
          <div className="w-full border bg-white border-gray-300 rounded py-2">
            <Column gap="medium" wGrow className="px-4 py-2">
              <Row grow y="center" between>
                <div className="text-xs text-gray-500">Business Name</div>
                <Row y="center" gap="small" className="w-[400px]">
                  <div className="text-sm font-medium">{vendorName}</div>
                  {setSelectedVendor && (
                    <IconButton
                      aria-label="Remove Vendor"
                      m="0"
                      variant="ghost"
                      icon={<Icon as={XMarkIcon} boxSize="4" />}
                      size="xs"
                      onClick={() => {
                        setSelectedVendor(null)
                      }}
                    />
                  )}
                </Row>
              </Row>
              <Row grow y="top" between>
                <div className="text-xs text-gray-500 relative top-[1px]">
                  Delivery Method
                </div>
                {!editVendorPayout && (
                  <Column gap="medium" className="w-[400px]">
                    <PaymentDetails
                      isLoading={vendorPayoutLoading || updateCheckLoading}
                      paymentVia={props.values.paymentVia}
                      setEditVendorPayout={setEditVendorPayout}
                      vendorAchPayoutMethod={vendorAchPayoutMethod}
                      vendorCheckPayoutMethod={vendorCheckPayoutMethod}
                    />
                  </Column>
                )}
                {editVendorPayout && (
                  <Column gap="medium" className="w-[400px]">
                    <FormSection title="Delivery Method">
                      <SelectInput
                        {...{
                          isClearable: false,
                          hasSuccess: true,
                          className: 'border rounded-md p-[1px]',
                          onChange: async (e) => {
                            props.setFieldValue('paymentVia', e.value)
                          },
                          styles: {
                            clearIndicator: (base) => ({
                              ...base,
                              display: 'none',
                            }),
                          },
                          name: 'paymentVia',
                          options: [
                            {
                              label: 'ACH',
                              value: 'ACH',
                            },
                            {
                              label: 'Check',
                              value: 'check',
                            },
                          ],
                        }}
                      />
                    </FormSection>
                    {props.values?.paymentVia === 'ACH' &&
                    props.values.vendorName ? (
                      <ACHForm
                        loading={bankRoutingLoading}
                        isDemo={false}
                        routingNumber={
                          props.values?.achDetails?.routingNumber || ''
                        }
                        accountNumber={
                          props.values?.achDetails?.accountNumber || ''
                        }
                        confirmedAccountNumber={
                          props.values?.achDetails?.confirmedAccountNumber || ''
                        }
                        bankName={props.values?.achDetails?.bankName}
                        onChangeRoutingNumber={(routingNumber: string) => {
                          props.setFieldValue('achDetails.bankName', undefined)
                          if (routingNumber.length === 9) {
                            search({
                              variables: {
                                bankRoutingNumber: routingNumber,
                              },
                            }).then((response) => {
                              if (
                                response.data?.bankRoutingNumber
                                  ?.bankRoutingNumber?.bankName
                              )
                                props.setFieldValue(
                                  'achDetails.bankName',
                                  response.data.bankRoutingNumber
                                    .bankRoutingNumber.bankName,
                                )
                            })
                          }
                          props.setFieldValue(
                            'achDetails.routingNumber',
                            routingNumber,
                          )
                        }}
                        onChangeAccountNumber={(accountNumber: string) => {
                          props.setFieldValue(
                            'achDetails.accountNumber',
                            accountNumber,
                          )
                        }}
                        onChangeConfirmAccountNumber={(
                          confirmAccountNumber: string,
                        ) => {
                          props.setFieldValue(
                            'achDetails.confirmedAccountNumber',
                            confirmAccountNumber,
                          )
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {props.values?.paymentVia === 'check' ? (
                      <AddressInput
                        {...{
                          valueKey: 'achDetails.recipientAddress',
                          apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                          label: 'Recipient Address',
                          onChangeAddress: (address: string) => {},
                          onChangeCity(city) {},
                          onChangeState(state) {},
                          onChangeZip(zip) {},
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Column>
                )}
              </Row>
            </Column>
          </div>
          {editVendorPayout ? (
            <Row gap="small" y="center" x="right" grow>
              <Button
                variant={'ghost'}
                label="Cancel"
                className="!text-sm !py-1 !h-[30px]"
                onClick={() => setEditVendorPayout(!editVendorPayout)}
              />
              <Button
                variant={'solid'}
                label="Save"
                isLoading={vendorPayoutLoading || updateCheckLoading}
                {...{
                  onClick: async () => {
                    await props.handleSubmit()
                    setEditVendorPayout(!editVendorPayout)
                  },
                }}
                className="!text-sm !py-1 !h-[30px]"
                {...{
                  isDisabled:
                    Object.keys(props.errors).length !== 0 ||
                    vendorPayoutLoading ||
                    updateCheckLoading,
                }}
              />
            </Row>
          ) : (
            ''
          )}
        </Column>
      )}
    </Form>
  )
}
