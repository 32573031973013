import { useMutation } from '@apollo/client'
import {
  Card,
  CardBody,
  Center,
  HStack,
  VStack,
  Icon,
  Text,
} from '@chakra-ui/react'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useState } from 'react'

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from 'react-plaid-link'
import {
  CreateMerchantAchPaymentMethodWithPlaidDocument,
  CreatePayoutMethodWithPlaidDocument,
  GetAllMerchantPaymentMethodsDocument,
  GetLinkTokenDocument,
  PaymentMethod,
} from '../../operations-types'

type MethodTypes = 'payout' | 'payment' | 'both'

type PlaidLinkProps = {
  methodType: MethodTypes
  onAddPaymentMethod?: (paymentMethod: PaymentMethod) => void
  onAddPayoutMethod?: (payoutMethodDetails: string) => void
  onboarding?: boolean
  setLoading?: (loading: boolean) => void
}

const PlaidLink = (props: PlaidLinkProps) => {
  const [token, setToken] = useState<string | null>(null)
  const [getLinkToken] = useMutation(GetLinkTokenDocument)
  const [createPayoutMethodWithPlaid] = useMutation(
    CreatePayoutMethodWithPlaidDocument,
    {
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  const [createPaymentMethodWithPlaid] = useMutation(
    CreateMerchantAchPaymentMethodWithPlaidDocument,
  )

  // get a link_token from your API when component mounts
  React.useEffect(() => {
    const createLinkToken = async () => {
      const tokenResponse = await getLinkToken()
      const linkToken = tokenResponse.data?.getLinkToken?.linkToken
      setToken(linkToken!)
    }
    createLinkToken()
  }, [])

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      props.setLoading && props.setLoading(true)
      const connectedBankAccount = metadata.accounts?.[0]
      let paymentMethod
      let payoutMethodDetails = null

      switch (props.methodType) {
        case 'payout':
          await createPayoutMethodWithPlaid({
            variables: {
              publicToken: publicToken,
              bankAccountId: connectedBankAccount.id,
            },
          })
          break
        case 'payment':
          paymentMethod = await createPaymentMethodWithPlaid({
            variables: {
              publicToken: publicToken,
              bankAccountId: connectedBankAccount.id,
            },
          })
          break
        case 'both':
          payoutMethodDetails = await createPayoutMethodWithPlaid({
            variables: {
              publicToken: publicToken,
              bankAccountId: connectedBankAccount.id,
            },
          })
          paymentMethod = await createPaymentMethodWithPlaid({
            variables: {
              publicToken: publicToken,
              bankAccountId: connectedBankAccount.id,
            },
          })
          break
        default:
          break
      }

      if (props.onAddPaymentMethod && paymentMethod) {
        props.onAddPaymentMethod(paymentMethod as PaymentMethod)
      }

      if (
        props.onAddPayoutMethod &&
        payoutMethodDetails !== null &&
        payoutMethodDetails.data &&
        payoutMethodDetails.data.createPayoutMethodWithPlaid &&
        payoutMethodDetails.data.createPayoutMethodWithPlaid.plaidData
      ) {
        props.onAddPayoutMethod(
          payoutMethodDetails.data?.createPayoutMethodWithPlaid?.plaidData,
        )
      }

      props.setLoading && props.setLoading(false)
    },
    [],
  )
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata)
  }, [])
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata)
  }, [])

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  }

  const {
    open,
    // error,
    // exit
  } = usePlaidLink(config)

  return (
    <VStack w="100%">
      <Card
        cursor="pointer"
        onClick={async () => {
          await open()
        }}
        w="100%"
        borderRadius="4px"
        border="1px"
        borderColor="gray.300"
        _hover={{
          borderRadius: 'base',
          borderColor: 'gray.800',
          border: '1px',
          boxShadow: 'outline',
          bg: 'outline 1px',
        }}
      >
        <CardBody w="100%" h="96px" px="3" pt="3" pb="40px">
          <HStack
            alignContent="right"
            justifyContent="right"
            pb="12px"
          ></HStack>

          <Center>
            <VStack
              w="100%"
              justifyContent="center"
              alignContent={'center'}
              spacing="2"
            >
              <Icon as={BuildingLibraryIcon} boxSize="32px" color="gray.400" />
              {!props.onboarding && (
                <Text fontSize="lg" fontWeight="medium">
                  ACH / eCheck
                </Text>
              )}
              <Text>Log in to your bank account</Text>
            </VStack>
          </Center>
        </CardBody>
      </Card>
    </VStack>
  )
}

export default PlaidLink
