import { NavLink, Outlet, useOutletContext } from 'react-router-dom'
import classNames from 'classnames'
import { Header, Row, Separator, UnstandardizedRow } from 'ui'
import { User } from '../../types'

export default function Settings() {
  const data = useOutletContext() as User

  const getHrefsAndText = () => {
    var hrefsAndText = []

    hrefsAndText = [
      ['/dashboard/settings', 'Account'],
      ['/dashboard/settings/team', 'Team'],
      ['/dashboard/settings/nickelpay', 'Nickel Pay'],
      ['/dashboard/settings/notifications', 'Notifications'],
      ['/dashboard/settings/billing', 'Billing'],
    ]

    return hrefsAndText
  }

  const navItems = getHrefsAndText().map(([href, text]) => {
    return (
      <li key={href} className="mr-2">
        <NavLink to={href} className={(isActive) => getClassName(isActive)} end>
          {text}
        </NavLink>
      </li>
    )
  })

  function getClassName({ isActive }: { isActive: boolean }) {
    return classNames(
      'inline-block',
      'p-4',
      'rounded-t-lg',
      { 'border-transparent': !isActive },
      'border-b-2',
      { 'hover:text-gray-600': !isActive },
      { 'hover:border-gray-300': !isActive },
      { 'border-nickel-purple': isActive },
      { 'text-nickel-purple': isActive },
    )
  }

  return (
    <div className="overflow-y-auto mx-10">
      <Row spacing="mediumThick"></Row>
      <UnstandardizedRow className="pt-4 pb-8">
        <Header variant="page">Settings</Header>
      </UnstandardizedRow>
      <div className="text-sm font-medium text-center text-gray-800 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">{navItems}</ul>
        <Separator orientation="horizontal" />
      </div>
      <Outlet context={data} />
    </div>
  )
}
