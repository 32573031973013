import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Card,
  CardBody,
  Flex,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import currency from 'currency.js'
import moment from 'moment'
import { useEffect } from 'react'
import sum from 'lodash/sum'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Column,
  OverlayHeaderWithStatus,
  OverlayTitle,
  Row,
  Separator,
  StatusPill,
  VFlex,
} from 'ui'
import SkeletonLoader from 'ui/src/components/Loaders/SkeletonLoader'
import ContainerProps from 'ui/src/types'

import {
  BillDocument,
  DeleteBillDocument,
  UserRole,
} from '../../operations-types'
import { VendorPayoutDetails } from './components/VendorPayoutDetails'
import { capitalize, lowerCase } from 'lodash'
import { useLoggedInStore } from '../layout/LoggedInStore'

type BillPayOverlayProps = {
  id: string
  onClose?: () => void
  onBack?: () => void
}

const BillPayCaption = tw.div<ContainerProps>`
  text-gray-500
  text-xs
  font-normal
  !w-[300px]
  whitespace-nowrap
`

const BillPayValue = tw.div<ContainerProps>`
  text-[13px]
  text-gray-800
  font-medium
  whitespace-nowrap
`

const getBillLink = (billId: string) => {
  if (import.meta.env.MODE === 'production') {
    return `https://app.qbo.intuit.com/app/bill?txnId=${billId}`
  } else {
    return `https://app.sandbox.qbo.intuit.com/app/bill?txnId=${billId}`
  }
}

const BillPayOverlay = (props: BillPayOverlayProps) => {
  const toaster = useToast()

  const {
    data,
    error,
    loading: billLoading,
  } = useQuery(BillDocument, {
    variables: { billId: props.id },
  })

  const [deleteBill, { loading: deleteBillLoading }] = useMutation(
    DeleteBillDocument,
    {
      refetchQueries: ['Bills'],
      onCompleted: () => {
        toaster({
          status: 'success',
          title: `Success`,
          description: `Bill has been deleted`,
        })
        props.onClose && props.onClose()
      },
    },
  )

  const bill = data?.bill?.bill
  const dueDate = moment(bill?.billData?.dueDate).format('MM/DD/YYYY') ?? ''

  const vendorPayoutMethods = bill?.vendor?.vendorPayoutMethods || []
  const vendorPayoutMethod = vendorPayoutMethods?.at(0)

  const payments = (
    bill?.billPayables?.flatMap((x) => x.billPayments) || []
  ).map((x) => {
    return {
      to: {
        accountName: `${vendorPayoutMethod?.bankName}`,
        accountNumber: vendorPayoutMethod?.accountNumber || '',
        type: vendorPayoutMethod?.accountType || '',
        street: vendorPayoutMethod?.street || '',
        city: vendorPayoutMethod?.city || '',
        state: vendorPayoutMethod?.state || '',
        zip: vendorPayoutMethod?.zip || '',
        street2: vendorPayoutMethod?.street2 || '',
      },
      amount: x?.submittedAmountInCents || 0,
      date: x?.createdAt,
      paymentId: x?.id,
      status: x?.status,
    }
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (error?.message) {
      toaster({
        status: 'error',
        title: error?.message,
      })
    }
  }, [error])

  const { getUser } = useLoggedInStore()
  const user = getUser()

  const IS_PERMITTED =
    user?.isUserPermitted(UserRole.Manager) ||
    !!user?.organization?.approvalPolicies?.length

  return (
    <>
      <OverlayHeaderWithStatus onBack={props.onBack} onClose={props.onClose}>
        {billLoading ? (
          <SkeletonLoader className="!w-[200px]" />
        ) : (
          <VFlex justifyContent="space-between">
            <StatusPill
              status={
                bill?.billData?.status === 'Paid'
                  ? 'success'
                  : bill?.billData?.status === 'VOIDED'
                  ? 'error'
                  : 'action'
              }
              spacing="xsmallNarrow"
            >
              {bill?.billData?.status}
            </StatusPill>
          </VFlex>
        )}
      </OverlayHeaderWithStatus>
      <Separator orientation="horizontal" />
      <Column wGrow grow overflowHidden>
        <Column className="w-full overflow-y-scroll">
          <Row className="w-full" y="center" between>
            {billLoading ? (
              <Row spacing="large">
                <SkeletonLoader className="!w-[200px]" />
              </Row>
            ) : (
              <OverlayTitle
                title={
                  bill?.billData?.supplierRef?.supplierName ||
                  `ID: ${bill?.billData?.supplierRef?.id}`
                }
                subtitle={bill?.billData?.reference || ''}
                quickbooksLink={
                  bill?.billSource === 'QUICKBOOKS'
                    ? getBillLink(bill?.externalId || '')
                    : undefined
                }
              />
            )}
          </Row>
          <Separator orientation="horizontal" />
          {billLoading ? (
            <Column wGrow gap="small" spacing="large">
              <SkeletonLoader className="!w-full" />
              <SkeletonLoader className="!w-[300px]" />
              <SkeletonLoader className="!w-[300px]" />
              <SkeletonLoader className="!w-[300px]" />
              <SkeletonLoader className="!w-[200px]" />
            </Column>
          ) : (
            <Column wGrow className="px-8">
              <Column wGrow className="py-10 pb-10 gap-3">
                <div className="text-xl font-semibold">Bill Details</div>
                <Row grow y="top">
                  <BillPayCaption>Issued On</BillPayCaption>
                  <Column wGrow>
                    <BillPayValue>
                      {moment(bill?.billData?.issueDate).format('MM/DD/YYYY') ??
                        ''}
                    </BillPayValue>
                  </Column>
                </Row>
                <Row grow y="top">
                  <BillPayCaption>Due On</BillPayCaption>
                  <Column wGrow>
                    <BillPayValue>{dueDate}</BillPayValue>
                  </Column>
                </Row>
                <Row grow y="top">
                  <BillPayCaption>Bill Amount</BillPayCaption>
                  <Column wGrow>
                    <BillPayValue>
                      {currency(bill?.billData?.amountDue || 0).format()}
                    </BillPayValue>
                  </Column>
                </Row>
                <Row grow y="top">
                  <BillPayCaption>Total Paid on Nickel</BillPayCaption>
                  <Column wGrow>
                    <BillPayValue>
                      {currency(
                        sum(
                          payments
                            .filter((x) =>
                              ['SUCCEEDED', 'PENDING'].includes(x.status || ''),
                            )
                            .map((x) => x.amount),
                        ) || 0,
                        {
                          fromCents: true,
                        },
                      ).format()}
                    </BillPayValue>{' '}
                  </Column>
                </Row>
              </Column>
              <Box w="100%" pb={10}>
                <VendorPayoutDetails
                  vendorId={bill?.vendor?.id || ''}
                  vendorName={bill?.vendor?.name || ''}
                  vendorAchPayoutMethod={
                    vendorPayoutMethods.find((x) => x?.type === 'ACH') ??
                    undefined
                  }
                  vendorCheckPayoutMethod={
                    vendorPayoutMethods.find((x) => x?.type === 'CHECK') ??
                    undefined
                  }
                />
              </Box>
              <Column wGrow className="pb-10" gap="medium">
                <div className="text-xl font-semibold">Payments</div>
                {bill?.billPayables?.flatMap((x) => x.billPayments).length ===
                0 ? (
                  <Row
                    grow
                    spacing="medium"
                    className="text-sm text-black bg-gray-100 rounded py-4"
                  >
                    You have not made any payments
                  </Row>
                ) : (
                  ''
                )}
                {payments.map((x, index) => {
                  return (
                    <Card w="100%" borderColor="gray.300" key={index}>
                      <CardBody w="100%" p="14px">
                        <Flex flexDirection="row" w="100%">
                          <Flex flexDirection="row" w="60%">
                            <VStack alignItems="start">
                              <Text fontSize="xs" color="gray.500">
                                Amount
                              </Text>
                              <Text className="text-sm text-gray-900 text-semibold">
                                {currency(x.amount || 0, {
                                  fromCents: true,
                                }).format()}
                              </Text>
                            </VStack>
                            <Spacer />
                            <Column gap="small">
                              <div className="text-xs text-gray-500">
                                Initiated On
                              </div>
                              <div className="text-sm text-gray-900 text-semibold">
                                {moment(parseInt(x?.date || '')).format(
                                  'MM/DD/YYYY',
                                ) ?? ''}
                              </div>
                            </Column>
                            <Spacer />
                            <Column gap="small">
                              <div className="text-xs text-gray-500">
                                Status
                              </div>
                              <StatusPill
                                status={
                                  x?.status === 'SUCCEEDED'
                                    ? 'success'
                                    : x?.status === 'FAILED'
                                    ? 'error'
                                    : 'action'
                                }
                                spacing="xsmallNarrow"
                              >
                                {capitalize(lowerCase(x?.status || ''))}
                              </StatusPill>
                            </Column>
                          </Flex>
                          <Spacer />
                          <Row
                            gap="small"
                            y="center"
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/dashboard/transactions/payable/${x?.paymentId}`,
                              )
                            }}
                          >
                            <div className="text-sm whitespace-nowrap">
                              View Payment
                            </div>
                            <ArrowRightIcon className="h-4 w-4 text-gray-800" />
                          </Row>
                        </Flex>
                      </CardBody>
                    </Card>
                  )
                })}
              </Column>
            </Column>
          )}
        </Column>
      </Column>
      <Separator orientation="horizontal" />
      <Row grow y="center" x="right" spacing="medium" className="px-10">
        <Row grow className="px-2" x="right" gap={'small'}>
          {bill?.billSource !== 'QUICKBOOKS' && (
            <Button
              label="Delete"
              variant="ghost"
              isLoading={deleteBillLoading}
              onClick={() =>
                deleteBill({
                  variables: {
                    billId: props.id,
                  },
                })
              }
            />
          )}
          {IS_PERMITTED && (
            <Button
              label="Make Payment"
              {...{
                onClick: () => navigate(`/send-money/bill/${props.id}`),
              }}
            />
          )}
        </Row>
      </Row>
    </>
  )
}

export default BillPayOverlay
