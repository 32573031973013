import { useMutation, useQuery } from '@apollo/client'
import {
  Card,
  Flex,
  Text,
  Badge,
  HStack,
  Spacer,
  IconButton,
  VStack,
  Divider,
  Link,
  useToast,
} from '@chakra-ui/react'
import { fetchPortalURL } from '../../lib/urls'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useState, useEffect } from 'react'
import { Button, ValidatingInput, PaymentLinkSection } from 'ui'
import {
  AdditionalInformation,
  QuestionValidationPayload,
} from '../forms/steps/AdditionalInformation'
import {
  EyeIcon,
  ArrowLeftIcon,
  LinkIcon,
  BanknotesIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentTextIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline'
import { useUpdateQuestionFormDocument } from '../forms/questionFormMutation'
import {
  QuestionFormDocument,
  QuestionFormSchema,
  QuestionForm,
  QuestionFormsDocument,
  SetPaymentLinkPreviewDocument,
} from '../../operations-types'
import TermsOfService, {
  TermsValidationPayload,
} from '../forms/steps/TermsOfService'
import EnablePaymentMethods from '../forms/EnablePaymentMethods'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { CHECKOUTS_URL } from '../../lib/urls'
import { QueryParameters } from '../forms/steps/QueryParameters'
import { User } from '../../types'
import Loading from '../utils/Loading'

function PaymentLinkCreationNavbar({
  currentQuestionForm,
  handlePreviewLink,
  handleCheckoutActive,
}: {
  currentQuestionForm: QuestionForm | undefined
  handlePreviewLink: () => void
  handleCheckoutActive: () => void
}) {
  const push = useNavigate()
  const updatedAt = new Date(parseInt(currentQuestionForm?.updatedAt || ''))
  const activated = !!currentQuestionForm?.checkoutActive
  return (
    <Flex w="100%">
      <Card w="100%" py="4" px="12" boxShadow="lg">
        <Flex flexDirection="row">
          <HStack>
            <IconButton
              colorScheme="gray"
              variant="ghost"
              aria-label="Search database"
              icon={<ArrowLeftIcon />}
              p="2"
              {...{
                onClick: () => push(CHECKOUTS_URL),
              }}
            />
            <Text>Payment Links / </Text>
            <Text fontWeight="semibold">{currentQuestionForm?.name}</Text>
          </HStack>
          <Spacer />
          <HStack>
            <Badge p="1" colorScheme={activated ? 'green' : 'gray'}>
              {activated ? 'Active' : 'Inactive'}
            </Badge>
            <Text>Updated on {updatedAt.toLocaleDateString()} </Text>
          </HStack>
          <Spacer />
          <HStack spacing="2">
            <IconButton
              p="2"
              colorScheme="dark"
              aria-label="Search database"
              icon={<EyeIcon />}
              variant="outline"
              onClick={handlePreviewLink}
            />
            <Button
              label={activated ? 'Deactivate' : 'Activate'}
              status={activated ? 'warning' : 'primary'}
              iconName={activated ? 'documentArrowDown' : 'documentArrowUp'}
              iconPosition="left"
              onClick={handleCheckoutActive}
            />
          </HStack>
        </Flex>
      </Card>
    </Flex>
  )
}

function PaymentLinkSectionPathName({
  saveOnClick,
  linkName,
  setLinkName,
  pathName,
  setPathName,
  subdomain,
  handlePreviewLink,
  nonUniquePathName,
  nonUniqueLinkName,
}: {
  saveOnClick: () => void
  linkName?: string
  setLinkName: (e: string) => void
  pathName?: string
  setPathName: (e: string) => void
  subdomain?: string | undefined
  handlePreviewLink: () => void
  nonUniqueLinkName?: boolean
  nonUniquePathName?: boolean
}) {
  const invalidLinkName = linkName === '' || !!nonUniqueLinkName
  const invalidPathName = pathName === '' || !!nonUniquePathName
  return (
    <PaymentLinkSection
      label="Name Your Payment Link"
      headerIcon={LinkIcon}
      saveOnClick={saveOnClick}
      disabled={
        linkName === '' ||
        pathName === '' ||
        !!nonUniqueLinkName ||
        !!nonUniquePathName
      }
    >
      <ValidatingInput
        label="Payment Link Name"
        placeholder="My Payment Link"
        isInvalid={invalidLinkName && !!linkName}
        validated={true}
        error={
          !!invalidLinkName
            ? 'Only unique and non-empty payment link names are permitted'
            : ''
        }
        value={linkName}
        onChange={(e) => setLinkName(e.target.value)}
      />
      <ValidatingInput
        label="Path URL"
        placeholder="My Payment Link"
        error={
          !!invalidPathName
            ? 'Only unique and non-empty path URLs are permitted'
            : ''
        }
        isInvalid={invalidPathName && !!pathName}
        validated={true}
        value={pathName}
        onChange={(e) =>
          setPathName(e.target.value.replace(/[^\w]/g, '').toLowerCase())
        }
      />
      <Link
        color="purple.500"
        onClick={handlePreviewLink}
        isExternal
        fontSize="xs"
        alignSelf="left"
        justifySelf="left"
      >
        {`https://${subdomain}.nickelpayments.com/${pathName}`}
        <ExternalLinkIcon m="1" mb="2" />
      </Link>
    </PaymentLinkSection>
  )
}

function PaymentLinkSectionQueryParameters({
  saveOnClick,
  validationPayload,
  setValidationPayload,
  currentSchema,
}: {
  saveOnClick: () => void
  validationPayload: QuestionValidationPayload
  setValidationPayload: (e: QuestionValidationPayload) => void
  currentSchema?: QuestionFormSchema
}) {
  return (
    <PaymentLinkSection
      headerIcon={LightBulbIcon}
      label="Query Parameters"
      saveOnClick={saveOnClick}
    >
      <QueryParameters
        validationPayload={validationPayload}
        setValidationPayload={setValidationPayload}
        currentSchema={currentSchema}
      />
    </PaymentLinkSection>
  )
}

function PaymentLinkSectionPaymentMethods({
  saveOnClick,
  creditCardEnabled,
  setCreditCardEnabled,
  achEnabled,
  setAchEnabled,
  feePassthroughPercent,
  setFeePassthroughPercent,
}: {
  saveOnClick: () => void
  creditCardEnabled: boolean
  setCreditCardEnabled: (e: boolean) => void
  achEnabled: boolean
  setAchEnabled: (e: boolean) => void
  feePassthroughPercent: string
  setFeePassthroughPercent: (e: string) => void
}) {
  return (
    <PaymentLinkSection
      headerIcon={BanknotesIcon}
      label="Enable Payment Methods"
      saveOnClick={saveOnClick}
    >
      <EnablePaymentMethods
        {...{
          creditCardEnabled,
          setCreditCardEnabled,
          achEnabled,
          setAchEnabled,
          feePassthroughPercent,
          setFeePassthroughPercent,
        }}
      />
    </PaymentLinkSection>
  )
}

function PaymentLinkSectionQuestions({
  currentSchema,
  validationPayload,
  setValidationPayload,
  saveOnClick,
}: {
  validationPayload: QuestionValidationPayload
  setValidationPayload: (e: QuestionValidationPayload) => void
  currentSchema: QuestionFormSchema
  saveOnClick: () => void
}) {
  return (
    <PaymentLinkSection
      label="Add Questions"
      sublabel="To collect information from your customers before they make payments."
      headerIcon={ChatBubbleOvalLeftEllipsisIcon}
      saveOnClick={saveOnClick}
      disabled={!validationPayload.valid.additional_information}
    >
      <AdditionalInformation
        {...{
          validationPayload: validationPayload,
          setValidationPayload: setValidationPayload,
          currentSchema: currentSchema,
        }}
      />
    </PaymentLinkSection>
  )
}

function PaymentLinkSectionUploadDocuments({
  currentSchema,
  setCurrentSchema,
  validationPayload,
  setValidationPayload,
  setSuccessfulUpload,
  successfulUpload,
  saveOnClick,
}: {
  validationPayload: TermsValidationPayload
  setValidationPayload: (e: TermsValidationPayload) => void
  currentSchema: QuestionFormSchema
  setCurrentSchema: (e: QuestionFormSchema) => void
  successfulUpload: boolean
  setSuccessfulUpload: (e: boolean) => void
  saveOnClick: () => void
}) {
  return (
    <PaymentLinkSection
      label="Upload A Document"
      sublabel="For your customers to review before they make payments."
      headerIcon={DocumentTextIcon}
      saveOnClick={saveOnClick}
      disabled={!successfulUpload}
    >
      <TermsOfService
        {...{
          validationPayload: validationPayload,
          setValidationPayload: setValidationPayload,
          currentSchema: currentSchema,
          setCurrentSchema: setCurrentSchema,
          setSuccessfulUpload: setSuccessfulUpload,
        }}
      />
    </PaymentLinkSection>
  )
}
function EditPaymentLink() {
  const { id } = useParams()
  const userData = useOutletContext() as User
  const [questionFormId] = useState(id || '')
  const toaster = useToast()
  const { data, refetch } = useQuery(QuestionFormDocument, {
    variables: {
      questionFormId: questionFormId || '',
    },
  })
  const [currentSchema, setCurrentSchema] = useState<QuestionFormSchema>({})
  const dataSchema = data?.questionForm?.questionForm?.schema || '{}'

  const [currentQuestionForm, setCurrentQuestionForm] = useState(
    data?.questionForm?.questionForm,
  )

  const [updateQuestionFormDocument] = useUpdateQuestionFormDocument({
    onCompleted: async () => {
      toaster({
        status: 'success',
        title: `Updated Question Form`,
      })

      await refetch()
    },
    onError: (e) => {
      toaster({
        status: 'error',
        title: e.message,
      })
    },
  })

  const [linkName, setLinkName] = useState(currentQuestionForm?.name || '')
  const [pathName, setPathName] = useState('')
  const subdomain = userData?.organization?.accountInfo?.subdomain || ''
  const [creditCardEnabled, setCreditCardEnabled] = useState(true)
  const [achEnabled, setAchEnabled] = useState(true)
  const [feePassthroughPercent, setFeePassthroughPercent] = useState('100')
  const [questionValidationPayload, setQuestionValidationPayload] =
    useState<QuestionValidationPayload>({
      steps: {
        additional_information: {
          questions: [],
          step: 0,
        },
      },
      query_parameters: [],
      new_additional_information: {
        questionType: 'text',
        question: '',
        required: false,
        label: '',
      },
      valid: {
        additional_information: false,
      },
    })

  const [termsValidationPayload, setTermsValidationPayload] =
    useState<TermsValidationPayload>({
      steps: {
        terms_of_service: {
          url: '',
          requireAcceptance: true,
          fileBuffer: currentSchema?.steps?.terms_of_service?.fileBuffer || '',
          step: currentSchema?.steps?.terms_of_service?.step || 0,
        },
      },
      valid: {
        terms_of_service: false,
      },
    })

  useEffect(() => {
    const questionFormSchema = JSON.parse(dataSchema) as QuestionFormSchema

    if (data?.questionForm?.questionForm) {
      setCurrentQuestionForm(data?.questionForm?.questionForm)
      setCurrentSchema(questionFormSchema)
      setLinkName(data?.questionForm?.questionForm?.name || '')
      setPathName(data?.questionForm?.questionForm?.checkoutPath || '')
      setTermsValidationPayload({
        ...termsValidationPayload,
        steps: {
          terms_of_service: JSON.parse(
            data?.questionForm?.questionForm?.schema || '{}',
          ).steps?.terms_of_service,
        },
        valid: {
          terms_of_service: true,
        },
      })
      setCreditCardEnabled(
        data?.questionForm?.questionForm?.creditCardEnabled || false,
      )
      setAchEnabled(data?.questionForm?.questionForm?.achEnabled || false)
      setFeePassthroughPercent(
        data?.questionForm?.questionForm?.feePassthroughPercent?.toString() ||
          '100',
      )
      setQuestionValidationPayload({
        ...questionValidationPayload,
        steps: {
          additional_information: JSON.parse(
            data?.questionForm?.questionForm?.schema || '{}',
          ).steps?.additional_information,
        },
        query_parameters: questionFormSchema?.query_parameters || [],
      })
    }
  }, [data])

  type PreviewLinkPayload = {
    checkoutPath: string
    subdomain: string
    previewSchema: {
      schema: QuestionFormSchema
      checkoutPath: string
      creditCardEnabled: boolean
      achEnabled: boolean
      feePassthroughPercent: number
      name: string
    }
  }

  const [previewLinkPayload, setPreviewLinkPayload] =
    useState<PreviewLinkPayload>({
      checkoutPath: currentQuestionForm?.checkoutPath || pathName,
      subdomain: subdomain,
      previewSchema: {
        schema: currentSchema,
        checkoutPath: currentQuestionForm?.checkoutPath || pathName,
        creditCardEnabled:
          currentQuestionForm?.creditCardEnabled || creditCardEnabled,
        achEnabled: currentQuestionForm?.achEnabled || achEnabled,
        feePassthroughPercent: currentQuestionForm?.feePassthroughPercent ?? 0,
        name: currentQuestionForm?.name || linkName,
      },
    })

  const [setPaymentLinkPreview] = useMutation(SetPaymentLinkPreviewDocument)

  useEffect(() => {
    if (data?.questionForm?.questionForm) {
      setPreviewLinkPayload({
        checkoutPath: data?.questionForm?.questionForm?.checkoutPath || '',
        subdomain: subdomain,
        previewSchema: {
          schema: JSON.parse(data?.questionForm?.questionForm?.schema || '{}'),
          checkoutPath: data?.questionForm?.questionForm?.checkoutPath || '',
          creditCardEnabled:
            data?.questionForm?.questionForm?.creditCardEnabled || false,
          achEnabled: data?.questionForm?.questionForm?.achEnabled || false,
          feePassthroughPercent:
            data?.questionForm?.questionForm?.feePassthroughPercent ?? 0,
          name: data?.questionForm?.questionForm?.name || '',
        },
      })
    }
  }, [data])

  const handlePreviewLink = async () => {
    await setPreviewLinkPayload({
      checkoutPath: currentQuestionForm?.checkoutPath || pathName,
      subdomain: subdomain,
      previewSchema: {
        schema: currentSchema,
        checkoutPath: currentQuestionForm?.checkoutPath || pathName,
        creditCardEnabled:
          currentQuestionForm?.creditCardEnabled || creditCardEnabled,
        achEnabled: currentQuestionForm?.achEnabled || achEnabled,
        feePassthroughPercent:
          currentQuestionForm?.feePassthroughPercent ||
          parseInt(feePassthroughPercent),
        name: currentQuestionForm?.name || linkName,
      },
    })
    setPaymentLinkPreview({
      variables: {
        checkoutPath: currentQuestionForm?.checkoutPath || pathName,
        subdomain: subdomain,
        previewSchema: JSON.stringify(previewLinkPayload.previewSchema),
      },
    })
    window.open(
      `${fetchPortalURL(subdomain)}?preview=${btoa(
        JSON.stringify({
          checkoutPath: currentQuestionForm?.checkoutPath,
        }),
      )}`,
      '_blank',
    )
  }

  const { data: questionFormsData } = useQuery(QuestionFormsDocument)

  const questionForms = questionFormsData?.questionForms?.questionForms
  const nonUniquePathName = questionForms?.some(
    (item: QuestionForm) =>
      item?.checkoutPath !== currentQuestionForm?.checkoutPath &&
      item?.checkoutPath === pathName,
  )
  const nonUniqueLinkName = questionForms?.some(
    (item: QuestionForm) =>
      item?.name !== currentQuestionForm?.name && item?.name === linkName,
  )

  const [successfulUpload, setSuccessfulUpload] = useState(false)

  if (!currentQuestionForm) {
    return <Loading />
  }

  return (
    <VStack>
      <PaymentLinkCreationNavbar
        currentQuestionForm={currentQuestionForm}
        handlePreviewLink={handlePreviewLink}
        handleCheckoutActive={async () => {
          await updateQuestionFormDocument({
            variables: {
              questionFormId: currentQuestionForm?.id || '',
              name: currentQuestionForm?.name,
              checkoutPath: currentQuestionForm?.checkoutPath || '',
              creditCardEnabled: currentQuestionForm?.creditCardEnabled,
              achEnabled: currentQuestionForm?.achEnabled,
              feePassthroughPercent: currentQuestionForm?.feePassthroughPercent,
              schema: currentSchema,
              checkoutActive: !currentQuestionForm?.checkoutActive,
            },
          })
        }}
      />
      <VStack gap="4" w="600px" py="12">
        <Flex flexDirection="row">
          <Spacer />
          <PaymentLinkSectionPathName
            nonUniquePathName={!!nonUniquePathName}
            nonUniqueLinkName={!!nonUniqueLinkName}
            linkName={linkName}
            setLinkName={setLinkName}
            pathName={pathName}
            setPathName={setPathName}
            subdomain={subdomain}
            handlePreviewLink={handlePreviewLink}
            saveOnClick={async () => {
              await updateQuestionFormDocument({
                variables: {
                  questionFormId: currentQuestionForm?.id || '',
                  name: linkName,
                  checkoutPath: pathName,
                  creditCardEnabled: currentQuestionForm?.creditCardEnabled,
                  achEnabled: currentQuestionForm?.achEnabled,
                  feePassthroughPercent:
                    currentQuestionForm?.feePassthroughPercent,
                  schema: currentSchema,
                  checkoutActive: currentQuestionForm?.checkoutActive,
                },
              })
            }}
          />
        </Flex>
        {(userData?.role === 'ADMIN' || userData?.role === 'USER_ADMIN') && (
          <Flex flexDirection="row">
            <Spacer />
            <PaymentLinkSectionQueryParameters
              validationPayload={questionValidationPayload}
              setValidationPayload={setQuestionValidationPayload}
              currentSchema={currentSchema}
              saveOnClick={async () => {
                const { valid, new_additional_information, ...currentSchema } =
                  questionValidationPayload
                await updateQuestionFormDocument({
                  variables: {
                    questionFormId: currentQuestionForm?.id || '',
                    name: currentQuestionForm?.name,
                    checkoutPath: currentQuestionForm?.checkoutPath || '',
                    creditCardEnabled: currentQuestionForm?.creditCardEnabled,
                    achEnabled: currentQuestionForm?.achEnabled,
                    feePassthroughPercent:
                      currentQuestionForm?.feePassthroughPercent,
                    schema: currentSchema,
                    checkoutActive: currentQuestionForm?.checkoutActive,
                  },
                })
              }}
            />
          </Flex>
        )}
        <Flex flexDirection="row">
          <Spacer />
          <PaymentLinkSectionPaymentMethods
            creditCardEnabled={creditCardEnabled}
            setCreditCardEnabled={setCreditCardEnabled}
            achEnabled={achEnabled}
            setAchEnabled={setAchEnabled}
            feePassthroughPercent={feePassthroughPercent}
            setFeePassthroughPercent={setFeePassthroughPercent}
            saveOnClick={async () => {
              await updateQuestionFormDocument({
                variables: {
                  creditCardEnabled: creditCardEnabled,
                  achEnabled: achEnabled,
                  feePassthroughPercent: parseInt(feePassthroughPercent),
                  name: currentQuestionForm?.name,
                  schema: currentSchema,
                  questionFormId: currentQuestionForm?.id || '',
                  checkoutPath: currentQuestionForm?.checkoutPath || '',
                  checkoutActive: currentQuestionForm?.checkoutActive,
                },
              })
            }}
          />
        </Flex>
        <HStack w="100%">
          <Divider orientation="horizontal" w="100%" />
          <Text>Optional</Text>
          <Divider orientation="horizontal" w="100%" />
        </HStack>
        <Flex flexDirection="row">
          <Spacer />
          <PaymentLinkSectionQuestions
            saveOnClick={async () => {
              const { valid, new_additional_information, ...currentSchema } =
                questionValidationPayload
              await updateQuestionFormDocument({
                variables: {
                  questionFormId: currentQuestionForm?.id || '',
                  name: currentQuestionForm?.name,
                  checkoutPath: currentQuestionForm?.checkoutPath || '',
                  creditCardEnabled: currentQuestionForm?.creditCardEnabled,
                  achEnabled: currentQuestionForm?.achEnabled,
                  feePassthroughPercent:
                    currentQuestionForm?.feePassthroughPercent,
                  schema: currentSchema,
                  checkoutActive: currentQuestionForm?.checkoutActive,
                },
              })
            }}
            currentSchema={currentSchema}
            validationPayload={questionValidationPayload}
            setValidationPayload={setQuestionValidationPayload}
          />
        </Flex>
        <Flex flexDirection="row">
          <Spacer />
          <PaymentLinkSectionUploadDocuments
            saveOnClick={async () => {
              const { valid, ...currentSchema } = termsValidationPayload
              await updateQuestionFormDocument({
                variables: {
                  questionFormId: currentQuestionForm?.id || '',
                  name: currentQuestionForm?.name,
                  checkoutPath: currentQuestionForm?.checkoutPath || '',
                  creditCardEnabled: currentQuestionForm?.creditCardEnabled,
                  achEnabled: currentQuestionForm?.achEnabled,
                  feePassthroughPercent:
                    currentQuestionForm?.feePassthroughPercent,
                  schema: currentSchema,
                  checkoutActive: currentQuestionForm?.checkoutActive,
                },
              })
            }}
            currentSchema={currentSchema}
            setCurrentSchema={setCurrentSchema}
            validationPayload={termsValidationPayload}
            setValidationPayload={setTermsValidationPayload}
            successfulUpload={successfulUpload}
            setSuccessfulUpload={setSuccessfulUpload}
          />
        </Flex>
      </VStack>
    </VStack>
  )
}

export default EditPaymentLink
