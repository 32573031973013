import { useMutation } from '@apollo/client'
import {
  Card,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useToast,
  Button as ChakraButton,
  Box,
  Link,
  Checkbox,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, FormikValidatingInput, PasswordField, VFlex } from 'ui'
import * as yup from 'yup'
import {
  SendRegistrationCodeDocument,
  ValidateEmailVerificationCodeDocument,
} from '../../../operations-types'
import { RegistrationLayout } from './RegistrationLayout'
import { useSelfServiceRegistrationStore } from './selfServiceRegistrationStore'
import { useAuth } from '../../../lib/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import posthog from 'posthog-js'

const AccountSchema = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Must be at least 8 characters')
    .required('Password is required'),
  termsAccepted: yup
    .boolean()
    .oneOf([true], 'You must accept the terms of service'),
})
type AccountStep = 'email' | 'verification'

type AccountSchemaValues = yup.InferType<typeof AccountSchema>

type AccountProps = {
  onNextStep: () => void
}

export function Account({ onNextStep }: AccountProps) {
  const toaster = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isSignedIn } = useAuth()
  const navigate = useNavigate()
  const [step, setStep] = useState<AccountStep>('email')
  const { setEmail, setPassword, email } = useSelfServiceRegistrationStore(
    (state) => ({
      setEmail: state.setEmail,
      setPassword: state.setPassword,
      email: state.email,
    }),
  )

  const { setToken } = useSelfServiceRegistrationStore((state) => ({
    setToken: state.setToken,
  }))
  const [sendRegistrationCode, { loading }] = useMutation(
    SendRegistrationCodeDocument,
    {
      onCompleted: (data) => {
        if (data?.sendRegistrationCode?.error?.message) {
          toaster({
            title: 'Error',
            description: data.sendRegistrationCode.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return
        }
        setStep('verification')
      },
    },
  )
  const [validateEmailVerificationCode] = useMutation(
    ValidateEmailVerificationCodeDocument,
    {
      onCompleted: (data) => {
        if (
          data?.validateEmailVerificationCode?.error?.message ||
          !data?.validateEmailVerificationCode?.data?.token
        ) {
          toaster({
            title: 'Error',
            description:
              data.validateEmailVerificationCode?.error?.message ||
              'An error occurred.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return
        }

        setToken(data.validateEmailVerificationCode?.data?.token)
        onNextStep()
      },
    },
  )

  if (isSignedIn()) {
    navigate('/dashboard')
  }

  useEffect(() => {
    if (posthog) {
      posthog.capture('registration_view', {
        source: searchParams.get('ref') || 'direct',
      })
    }
  }, [searchParams, setSearchParams])

  const components: Record<AccountStep, React.ReactNode> = {
    email: (
      <RegistrationLayout
        title="Create an account"
        subtitle="Enter an email and password to get started."
        currentPage="email"
      >
        <Flex width={['340px', '540px']} flexDirection="column">
          <Formik<AccountSchemaValues>
            initialValues={{
              email: '',
              password: '',
              termsAccepted: false,
            }}
            onSubmit={(values) => {
              setEmail(values.email)
              setPassword(values.password)
              sendRegistrationCode({ variables: { email: values.email } })
            }}
            validationSchema={AccountSchema}
          >
            {(props) => {
              const onClick = () => {
                props.setFieldTouched('email', true)
                props.setFieldTouched('password', true)
                props.validateForm()

                if (props.isValid) {
                  props.submitForm()
                }
              }

              return (
                <VFlex gap={8}>
                  <Card py={8} px={6}>
                    <Flex flexDirection="column" gap={6}>
                      <FormikValidatingInput
                        fieldName="email"
                        label="Company Email"
                        placeholder="you@yourcompany.com"
                        fontSize="md"
                        size="md"
                      />
                      <PasswordField<AccountSchemaValues>
                        fieldName="password"
                        placeholder="·············"
                      />
                      <HStack>
                        <Checkbox
                          id="termsAccepted"
                          checked={props.values.termsAccepted}
                          onChange={props.handleChange}
                        />
                        <Text fontSize="md" fontWeight="md">
                          I agree to the{' '}
                          <Link
                            href="https://www.getnickel.com/terms-of-use"
                            fontSize="md"
                            fontWeight="md"
                            color="purple.700"
                            isExternal
                          >
                            Terms of Service
                          </Link>
                        </Text>
                      </HStack>
                    </Flex>
                  </Card>
                  <Button
                    label="Sign Up"
                    isLoading={loading}
                    onClick={onClick}
                    isDisabled={!props.values.termsAccepted}
                  />
                </VFlex>
              )
            }}
          </Formik>
        </Flex>
      </RegistrationLayout>
    ),
    verification: (
      <RegistrationLayout
        title="Verify your email"
        subtitle={`Enter the six digit code we just sent to ${email}.`}
        currentPage="email"
      >
        <Flex width={['340px', '540px']} flexDirection="column">
          <HStack>
            <PinInput
              size="lg"
              onComplete={(val) => {
                validateEmailVerificationCode({
                  variables: { email: email!, code: val },
                })
              }}
            >
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
              <PinInputField
                _focus={{
                  borderColor: 'dark.500',
                }}
              />
            </PinInput>
          </HStack>
        </Flex>
        <VFlex gap={2}>
          <Text fontSize="sm" color="gray.500">
            Didn't get it? Wait a few minutes or request a new code.
          </Text>
          <Box>
            <ChakraButton
              size="sm"
              h="1.5rem"
              isLoading={loading}
              onClick={async () => {
                const { data } = await sendRegistrationCode({
                  variables: { email: email! },
                })
                if (data?.sendRegistrationCode?.error) {
                  toaster({
                    title: 'Error',
                    description: data?.sendRegistrationCode.error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  })
                  return
                }

                toaster({
                  title: 'Success',
                  description: 'Code sent successfully',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                })
              }}
            >
              Resend Code
            </ChakraButton>
          </Box>
        </VFlex>
      </RegistrationLayout>
    ),
  }

  return <>{components[step]}</>
}
