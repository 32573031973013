import { Box } from '@chakra-ui/react'
import { useDashboardOutletContext } from '../../lib/outletContext'
import OnboardingDashboardReminder from '../onboarding/OnboardingDashboardReminder'
import ScrollableColumn from '../utils/ScrollableColumn'

type PageLayoutProps = {
  children: React.ReactNode
}

function BaseLayout({ children }: PageLayoutProps) {
  const { organization } = useDashboardOutletContext()

  const onboardingInfo = JSON.parse(
    organization?.accountInfo?.onboardingInfo || '{}',
  )

  return (
    <>
      {!onboardingInfo.applicationSubmitted ? (
        <OnboardingDashboardReminder />
      ) : (
        ''
      )}
      {children}
    </>
  )
}

export function PageLayout({ children }: PageLayoutProps) {
  return (
    <BaseLayout>
      <ScrollableColumn
        grow
        className="max-h-screen bg-gray-100"
        overflowScrollable
      >
        <Box w="100%" className="min-h-screen" padding={'14px'}>
          {children}
        </Box>
      </ScrollableColumn>
    </BaseLayout>
  )
}
