import { create } from 'zustand'
import { OnboardingFormInfo } from '../../../operations-types'

export type RegistrationPage = 'email' | 'owner' | 'business' | 'bankAccount'

type BusinessType = 'accounting' | 'business'

type OnboardingInfo = OnboardingFormInfo & {
  businessName: string
  dbaName: string
}

type SelfServiceRegistrationData = {
  step: RegistrationPage
  token?: string
  email?: string
  password?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  businessType?: BusinessType
  onboardingInfo?: OnboardingInfo
  plaidPublicToken?: string
  subdomain?: string
}

type SelfServiceRegistrationFunctions = {
  setStep: (step: RegistrationPage) => void
  setToken: (token: string) => void
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setDateOfBirth: (dateOfBirth: string) => void
  setBusinessType: (businessType: BusinessType) => void
  setOnboardingInfo: (onboardingInfo: OnboardingInfo) => void
  setPlaidPublicToken: (plaidPublicToken: string) => void
  setSubdomain: (subdomain: string) => void
  setEmail: (email: string) => void
  setPassword: (password: string) => void
}

type SelfServiceRegistrationState = SelfServiceRegistrationData &
  SelfServiceRegistrationFunctions

const initialState: SelfServiceRegistrationData = {
  step: 'email',
}

export const useSelfServiceRegistrationStore =
  create<SelfServiceRegistrationState>((set) => ({
    ...initialState,
    setStep: (step: RegistrationPage) => set({ step }),
    setToken: (token: string) => set({ token }),
    setFirstName: (firstName: string) => set({ firstName }),
    setLastName: (lastName: string) => set({ lastName }),
    setDateOfBirth: (dateOfBirth: string) => set({ dateOfBirth }),
    setBusinessType: (businessType: BusinessType) => set({ businessType }),
    setOnboardingInfo: (onboardingInfo: OnboardingInfo) =>
      set({ onboardingInfo }),
    setPlaidPublicToken: (plaidPublicToken: string) =>
      set({ plaidPublicToken }),
    setSubdomain: (subdomain: string) => set({ subdomain }),
    setEmail: (email: string) => set({ email }),
    setPassword: (password: string) => set({ password }),
  }))
