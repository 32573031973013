import { tw } from '@nickeltech/brise'
import {
  Button,
  Column,
  Form,
  OverlayTitle,
  Row,
  Separator,
  TrashIcon,
  replaceTrailingZeroes,
  calculateFeePercentage,
  ValidatingInput,
  PriceSelector,
  CardInput,
  DatePickerCardInput,
  STAGING_ORG_IDS,
} from 'ui'
import ContainerProps from 'ui/src/types'
import {
  Box,
  FormLabel,
  HStack,
  Text,
  VStack,
  Icon,
  IconButton,
  Flex,
  Spacer,
  Badge,
  Card,
  CardBody,
  Switch,
  useToast,
  Textarea,
  Input,
} from '@chakra-ui/react'
import { createRef, useEffect, useState } from 'react'
import { Upload } from 'antd/lib/index'
import {
  DocumentArrowUpIcon,
  DocumentTextIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import * as yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  AddQuestionFormDocument,
  GetInstructionsInvoiceAnalysisDocument,
  QuestionFormStatus,
  UploadInvoiceForInstructionsAnalysisDocument,
} from '../../operations-types'
import { FieldArray } from 'formik'
import { Document, Page, pdfjs } from 'react-pdf'
import currency from 'currency.js'
import { useCodatLinkedConnection } from '../utils/CodatUtils'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { QBOChartOfAccountsModal } from '../erp/QBOChartofAccounts'
import UpdateBankAccount from '../settings/billing/UpdateBankAccount'
import NotificationArea from '../utils/NotificationArea'
import ErrorNotification from '../utils/ErrorNotification'
import Notification from '../utils/Notification'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'
import { PaymentLinkElements } from './PaymentLinkElements'
import { TradeAccountSelect } from './components/TradeAccountSelect'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useLoggedInStore } from '../layout/LoggedInStore'

type CreatePaymentLinkOverlayProps = {
  onClose?: () => void
  onBack?: () => void
  setSuccess?: (success: boolean) => void
  setNewPaymentLinkId?: (id: string) => void
  focusRef?: React.RefObject<HTMLInputElement>
  hubSpotInitialValues?: {
    amount: string
    memo: string
    dealName: string
  }
}

const OverlayHeader = tw.div<ContainerProps>`
  text-lg
  font-bold
  text-gray-800
`

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const { Dragger } = Upload

type UploadedFile = {
  data: string | ArrayBuffer | null
  name: string
}

type FileUploadDraggerProps = {
  onFile: (e: UploadedFile) => void
  accept?: string
}

export const FileUploadDragger = (props: FileUploadDraggerProps) => {
  return (
    <Box w="100%" bgColor="white">
      <Dragger
        {...props}
        customRequest={() => {}}
        beforeUpload={async (file) =>
          await new Promise(() => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              props.onFile({
                data: reader.result,
                name: file.name,
              })
            }
          })
        }
      >
        <HStack px="2">
          <Icon as={DocumentArrowUpIcon} boxSize="4" />
          <Text color="purple.600" fontSize="xs" fontWeight="medium">
            Click or drag file to this area to upload
          </Text>
        </HStack>
      </Dragger>
    </Box>
  )
}

const CreatePaymentLinkSchema = yup.object({
  name: yup.string().required(),
  amount: yup.string().required(),
  amountEditable: yup.boolean(),
  feePassthroughPercent: yup
    .number()
    .min(0, 'Percentage must be between 0 and 100')
    .max(100, 'Percentage must be between 0 and 100')
    .optional(),
  memo: yup.string(),
  fileBuffer: yup.array().of(yup.string()),
  invoiceId: yup.string().optional(),
  customer: yup.object({
    customerId: yup.string(),
    customerName: yup.string(),
    emailAddress: yup.string().email(),
  }),
})

type FeePassThroughSettingsProps = {
  passthroughActive: boolean
  setPassthroughActive: (passthroughActive: boolean) => void
  onSwitchClick: () => void
  error: string | undefined
  feePassthroughPercent: number | null | undefined
  inputId: string
  handleFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSaveClick?: () => void
  onCancelClick?: () => void
}

export const FeePassThroughSettings = ({
  passthroughActive,
  setPassthroughActive,
  onSaveClick,
  onCancelClick,
  onSwitchClick,
  error,
  feePassthroughPercent,
  handleFeeChange,
  inputId,
}: FeePassThroughSettingsProps) => {
  return (
    <Card m="0" p="0">
      <CardBody p="4">
        <VStack spacing="0" gap="0">
          <HStack spacing="0" gap="0" w="100%">
            <VStack alignItems="start" spacing="0" gap="0" w="100%">
              <Text fontSize="sm" fontWeight="medium">
                Customize fees on this payment request
              </Text>
              <Text fontSize="xs" color="gray.500">
                Enable this option to customize the cost of card processing to
                your customers
              </Text>
            </VStack>
            <Switch
              isChecked={passthroughActive}
              onChange={() => {
                onSwitchClick()
                setPassthroughActive(!passthroughActive)
              }}
            />
          </HStack>
          {passthroughActive && (
            <HStack w="100%" alignItems="start" py="2">
              <VStack
                spacing="0"
                w="100%"
                gap="4"
                alignItems="center"
                justifyItems="center"
              >
                <Text
                  fontWeight={'medium'}
                  textColor={'gray.800'}
                  fontSize={'sm'}
                >
                  Your customer pays
                </Text>

                <Text fontSize="sm" textColor={'gray.500'}>
                  {`${replaceTrailingZeroes(
                    calculateFeePercentage(feePassthroughPercent || 0),
                  )}%`}
                </Text>
              </VStack>
              <VStack
                w="100%"
                spacing="0"
                gap="0"
                justifyItems="center"
                alignItems="start"
              >
                <ValidatingInput
                  id={inputId}
                  type="tel"
                  fontSize="sm"
                  w="30%"
                  onChange={handleFeeChange}
                  isInvalid={!!error}
                  label="Fee Percentage"
                  placeholder="%"
                  value={feePassthroughPercent || ''}
                  error={error}
                  rightAddon="%"
                />
              </VStack>
            </HStack>
          )}
        </VStack>
        {!!onSaveClick && !!onCancelClick && (
          <Row gap="small" y="center" className="justify-end">
            <Button
              variant={'ghost'}
              label="Cancel"
              className="!text-sm !py-1 !h-[30px]"
              onClick={() => {
                onCancelClick()
              }}
            />
            <Button
              variant={'solid'}
              label="Save"
              {...{
                onClick: async () => {
                  onSaveClick()
                },
              }}
              className="!text-sm !py-1 !h-[30px]"
            />
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

type CreatePaymentLinkSchemaType = yup.InferType<typeof CreatePaymentLinkSchema>

function CreatePaymentLinkOverlay(props: CreatePaymentLinkOverlayProps) {
  const { organization } = useDashboardOutletContext()

  const { user } = useLoggedInStore((state) => ({
    user: state.user,
  }))

  const [fileNames, setFileNames] = useState<string[]>([])
  const [file, setFile] = useState<string | ArrayBuffer | null>(null)
  const ref = createRef<HTMLCanvasElement>()

  const [addQuestion, { loading }] = useMutation(AddQuestionFormDocument, {
    onCompleted(data) {
      if (data.addQuestionForm?.error) {
        console.error('Error adding question form', data.addQuestionForm.error)
      } else {
        props.setNewPaymentLinkId &&
          props.setNewPaymentLinkId(
            data.addQuestionForm?.questionForm?.id || '',
          )
      }
    },
    refetchQueries: ['questionForms'],
  })

  const [chartOfAccountModalOpen, setChartOfAccountModalOpen] = useState(false)
  const bankAccount = organization?.accountInfo?.bankAccounts?.at(0)

  const { hasLinkedConnection } = useCodatLinkedConnection(
    organization?.accountInfo ?? null,
  )

  const { setPaymentLinks } = usePaymentLinkStore()

  const [updateBankOpen, setUpdateBankOpen] = useState(false)
  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })
  const [showError, setShowError] = useState({
    message: '',
    show: false,
  })

  const [passthroughActive, setPassthroughActive] = useState(false)

  const toast = useToast()

  useEffect(() => {
    toast.closeAll()
    setPaymentLinks([])
  }, [])

  const nickelCreditEnabled = useFeatureFlagEnabled('nickelCreditEnabled')

  const [dueDate, setDueDate] = useState<Date>(new Date())

  const [projectId, setProjectId] = useState<string | null>(null)
  const [termsSelected, setTermsSelected] = useState<Date | null>()
  const [questionFormId, setQuestionFormId] = useState<string | null>(null)

  const [invoiceAmount, setInvoiceAmount] = useState<number | null>(null)

  const [isPolling, setIsPolling] = useState(false)

  const [approved, setApproved] = useState(false)

  const { stopPolling, startPolling, refetch } = useQuery(
    GetInstructionsInvoiceAnalysisDocument,
    {
      variables: {
        questionFormId: questionFormId!,
        amountInCents: invoiceAmount!,
      },
      skip: !isPolling || !questionFormId || !invoiceAmount,
      fetchPolicy: 'network-only',
      onCompleted: async (data) => {
        if (data.getInstructionsInvoiceAnalysis?.error) {
          setIsPolling(false)
          setApproved(false)
          setShowError({
            message: 'Error with invoice, please reupload',
            show: true,
          })
        }
        if (data.getInstructionsInvoiceAnalysis?.approved) {
          setApproved(true)
          setIsPolling(false)
          setShowNotif({
            message: 'Invoice approved',
            show: true,
            header: 'Success',
          })
        } else if (data?.getInstructionsInvoiceAnalysis?.approved === false) {
          setApproved(false)
          setIsPolling(false)
          setShowError({
            message: `Invoice not approved: ${
              data?.getInstructionsInvoiceAnalysis?.analysis ??
              'Please reupload an invoice without payment instructions and matching the amount.'
            }`,
            show: true,
          })
        }
      },
    },
  )

  const resetInvoiceAnalysis = () => {
    setIsPolling(false)
    setApproved(false)
    setShowError({ message: '', show: false })
    setShowNotif({ message: '', show: false, header: '' })
  }

  const [uploadInvoiceForAnalysis] = useMutation(
    UploadInvoiceForInstructionsAnalysisDocument,
    {
      onCompleted: async () => {
        refetch()
        setIsPolling(true)
      },
    },
  )

  useEffect(() => {
    if (isPolling) {
      startPolling(500)
    } else {
      stopPolling()
    }
  }, [isPolling])

  return (
    <>
      <Form<CreatePaymentLinkSchemaType>
        {...{
          className: 'w-full h-full',

          initialValues: {
            amount: props.hubSpotInitialValues?.amount || '',
            amountEditable:
              organization?.accountInfo?.paymentSettings
                .paymentAmountEditable ?? false,
            name: props.hubSpotInitialValues?.dealName || '',
            memo: props.hubSpotInitialValues?.memo || '',
            feePassthroughPercent:
              organization?.accountInfo?.paymentSettings.feePassthroughPercent,
            fileBuffer: [''],
            invoiceId: '',
            customer: {
              customerId: '',
              customerName: '',
              emailAddress: '',
            },
          },
          validationSchema: CreatePaymentLinkSchema,
        }}
      >
        {(formik) => {
          const overviewNode = (
            <Flex flexDirection="column" w="100%" py={4} gap={6}>
              <Flex flexDirection="column">
                <OverlayHeader>Payment Details</OverlayHeader>
                <Text fontSize="sm" color="gray.500">
                  Customers will see this information on the payments page and
                  in corresponding emails that we send.
                </Text>
              </Flex>
              <CardInput
                label="Purpose"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                inputGroupSize="md"
                fontSize="xl"
                bgColor="white"
                labelColor="gray.800"
                placeholder="Deposit or invoice number"
              />
              <HStack>
                <PriceSelector
                  name="amount"
                  value={formik.values.amount}
                  onChange={(e) => {
                    formik.setFieldValue('amount', e)
                    setTermsSelected(null)
                    setInvoiceAmount(currency(e).intValue)
                    formik.setFieldValue('fileBuffer', [''])
                    setFileNames([])
                    resetInvoiceAnalysis()
                  }}
                  bgColor="white"
                  labelColor="gray.800"
                  placeholder="$0"
                  fontSize="xl"
                />
                {!STAGING_ORG_IDS.includes(user.organization.id) && (
                  <DatePickerCardInput
                    label="Due Date"
                    className="text-sm"
                    selected={dueDate}
                    onChange={(d) => setDueDate(d)}
                    disabled={
                      termsSelected !== null && termsSelected !== undefined
                    }
                  />
                )}
              </HStack>
              {nickelCreditEnabled && (
                <TradeAccountSelect
                  setProjectId={setProjectId}
                  projectId={projectId || ''}
                  setTermsSelected={(date) => {
                    setTermsSelected(date)
                    if (date) {
                      setDueDate(date)
                    }
                  }}
                  termsSelected={termsSelected || null}
                  amount={formik.values.amount}
                  setQuestionFormId={setQuestionFormId}
                />
              )}
              <Flex flexDirection="column">
                <OverlayHeader>Additional information</OverlayHeader>
                <Text fontSize="sm" color="gray.500">
                  Customers will see this information on the payments page and
                  in corresponding emails that we send.
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <FormLabel>Memo (Optional)</FormLabel>
                <Input
                  as={Textarea}
                  value={formik.values.memo}
                  name="memo"
                  placeholder="Descriptive memo or note for the payment"
                  onChange={formik.handleChange}
                />
              </Flex>
              <VStack
                alignItems="left"
                spacing="0"
                gap="0"
                display={
                  STAGING_ORG_IDS.includes(user.organization.id) &&
                  questionFormId &&
                  invoiceAmount
                    ? 'block'
                    : !STAGING_ORG_IDS.includes(user.organization.id)
                    ? 'block'
                    : 'none'
                }
              >
                <FieldArray
                  name="fileBuffer"
                  render={(arrayHelpers) => (
                    <>
                      <VStack spacing="0" gap="0" alignItems="start">
                        <FormLabel
                          mb={
                            STAGING_ORG_IDS.includes(user?.organization?.id!)
                              ? '0'
                              : '2'
                          }
                        >
                          {STAGING_ORG_IDS.includes(user?.organization?.id!)
                            ? 'Upload Invoice (Required)'
                            : 'Attach files (Optional)'}
                        </FormLabel>
                        {STAGING_ORG_IDS.includes(user.organization.id) && (
                          <Text fontSize="sm" color="gray.500" mb="2">
                            You must upload an invoice with the same total
                            amount due.
                          </Text>
                        )}
                      </VStack>
                      {STAGING_ORG_IDS.includes(user.organization.id) &&
                        fileNames.length === 0 && (
                          <FileUploadDragger
                            {...{
                              accept:
                                '.pdf,.doc,.docx,.xls,.xlsx,.csv,.png,.jpg,.jpeg',
                              onFile: async (e) => {
                                setFile(e.data)
                                await arrayHelpers.push(e.data)
                                setFileNames([...fileNames, e.name])

                                if (
                                  STAGING_ORG_IDS.includes(user.organization.id)
                                ) {
                                  await uploadInvoiceForAnalysis({
                                    variables: {
                                      pdfBytes: e.data as string,
                                      questionFormId: questionFormId!,
                                    },
                                  })
                                }
                              },
                            }}
                          />
                        )}

                      {!STAGING_ORG_IDS.includes(user.organization.id) && (
                        <FileUploadDragger
                          {...{
                            accept:
                              '.pdf,.doc,.docx,.xls,.xlsx,.csv,.png,.jpg,.jpeg',
                            onFile: async (e) => {
                              setFile(e.data)
                              await arrayHelpers.push(e.data)
                              setFileNames([...fileNames, e.name])
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                />
                {fileNames.map((file, index) => (
                  <Flex key={index} flexDirection="row" gap="0" w="100%" p="2">
                    <Icon as={DocumentTextIcon} boxSize="5" />
                    <Text ps="2">{file}</Text>
                    <Spacer />
                    <Badge
                      p="1"
                      colorScheme="green"
                      color="green.500"
                      fontWeight="medium"
                    >
                      Uploaded
                    </Badge>
                    <IconButton
                      as={TrashIcon}
                      aria-label="Delete"
                      variant="ghost"
                      boxSize="5"
                      {...{
                        onClick: async () => {
                          await formik.setFieldValue(
                            'fileBuffer',
                            formik.values.fileBuffer?.filter(
                              (buffer, i) => i !== index,
                            ),
                          )
                          await setFileNames(
                            fileNames.filter((name) => name !== file),
                          )
                          if (STAGING_ORG_IDS.includes(user.organization.id)) {
                            resetInvoiceAnalysis()
                            formik.setFieldValue('fileBuffer', [''])
                            setFileNames([])
                          }
                        },
                      }}
                    />
                  </Flex>
                ))}
                <Box display="none">
                  <Document file={file}>
                    <Page
                      pageNumber={1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      width={80}
                      height={100}
                      canvasRef={ref}
                    />
                  </Document>
                </Box>
              </VStack>
            </Flex>
          )
          const advancedNode = (
            <Flex flexDirection="column" w="100%" py={4} gap={4}>
              <VStack spacing="4" gap="4" alignItems="left" w="100%">
                <VStack spacing="0" gap="0" alignItems="left" w="100%">
                  <OverlayHeader>Payment Settings</OverlayHeader>
                  <Text fontSize="sm" color="gray.500">
                    Customers will see this information on the payments page and
                    in corresponding emails that we send.
                  </Text>
                </VStack>

                <Card m="0" p="0">
                  <CardBody p="4">
                    <HStack spacing="0" gap="0" w="100%">
                      <VStack alignItems="start" spacing="0" gap="0" w="100%">
                        <Text fontSize="sm" fontWeight="medium">
                          Allow customers to edit the payment amount
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          Enable this option to allow customers to make multiple
                          payments or short pay
                        </Text>
                      </VStack>
                      <Switch
                        name="amountEditable"
                        isChecked={formik.values.amountEditable}
                        onChange={formik.handleChange}
                      />
                    </HStack>
                  </CardBody>
                </Card>
                <FeePassThroughSettings
                  inputId="feePassthroughPercent"
                  passthroughActive={passthroughActive}
                  setPassthroughActive={setPassthroughActive}
                  feePassthroughPercent={formik.values.feePassthroughPercent}
                  error={formik.errors.feePassthroughPercent}
                  onSwitchClick={() => {
                    formik.setFieldTouched('feePassthroughPercent', true)
                  }}
                  handleFeeChange={(e) => {
                    formik.setFieldValue(
                      'feePassthroughPercent',
                      parseInt(e.target.value),
                    )
                    formik.setFieldTouched('feePassthroughPercent', true)
                  }}
                />
              </VStack>
            </Flex>
          )

          return (
            <>
              <NotificationArea>
                <Notification
                  show={showNotif['show']}
                  onClose={() =>
                    setShowNotif({ message: '', show: false, header: '' })
                  }
                  header={showNotif['header']}
                  message={showNotif['message']}
                />
                <ErrorNotification
                  show={showError['show']}
                  onClose={() => setShowError({ message: '', show: false })}
                  errorMessage={showError['message']}
                />
              </NotificationArea>
              <UpdateBankAccount
                open={updateBankOpen}
                setOpen={setUpdateBankOpen}
                setShowError={setShowError}
                setShowNotif={setShowNotif}
                gqlToken={''}
                noToken={true}
                onCompleteAction={() => {
                  if (hasLinkedConnection) {
                    setChartOfAccountModalOpen(true)
                  }
                }}
              />
              <QBOChartOfAccountsModal
                isOpen={chartOfAccountModalOpen}
                setModalOpen={setChartOfAccountModalOpen}
                payoutMethodId={bankAccount?.id || ''}
                type={'bankAccount'}
                accountString={`your bank account at ${bankAccount?.bankName} ·· ${
                  bankAccount?.lastTwo || ''
                }`}
                onCompleted={() => {
                  setChartOfAccountModalOpen(false)
                }}
              />
              <Column className="w-full h-full">
                <Flex flexDirection="row" pe="6" w="100%">
                  <OverlayTitle
                    title="Request Payment"
                    subtitle="Create a secure payment link to collect payment from anyone"
                  />
                  <Spacer />
                  <Box alignContent="top" h="100%" py="5">
                    <Icon
                      as={XMarkIcon}
                      cursor="pointer"
                      boxSize={5}
                      className="stroke-gray-500"
                      onClick={props.onClose}
                    />
                  </Box>
                </Flex>
                <Separator orientation="horizontal" />
                <PaymentLinkElements
                  nodes={[
                    {
                      label: 'Overview',
                      node: overviewNode,
                    },
                    {
                      label: 'Advanced',
                      node: advancedNode,
                    },
                  ]}
                />
                <Box mt="auto" w="100%">
                  <Separator orientation="horizontal" />
                  <HStack
                    w="100%"
                    alignItems="end"
                    justifyContent="end"
                    p="4"
                    spacing="4"
                  >
                    <Button
                      isDisabled={
                        formik.values.name === '' ||
                        formik.values.amount === '' ||
                        loading ||
                        Object.keys(formik.errors).length > 0
                      }
                      isLoading={isPolling}
                      variant={
                        formik.values.customer.customerName &&
                        formik.values.customer.emailAddress
                          ? 'outline'
                          : 'solid'
                      }
                      label={isPolling ? 'Processing' : 'Create Link'}
                      {...{
                        onClick: async () => {
                          if (STAGING_ORG_IDS.includes(user.organization.id)) {
                            if (
                              termsSelected &&
                              formik?.values?.fileBuffer?.length === 1
                            ) {
                              toast({
                                status: 'error',
                                title: 'You must attach an invoice to factor',
                              })
                              return
                            }

                            if (projectId && !termsSelected) {
                              toast({
                                status: 'error',
                                title: 'You must select the term to factor',
                              })
                              return
                            }

                            if (termsSelected && !projectId) {
                              toast({
                                status: 'error',
                                title: 'You must select the project to factor',
                              })
                              return
                            }

                            if (!approved) {
                              toast({
                                status: 'error',
                                title:
                                  'Invoice not approved, please upload a valid invoice.',
                              })
                              return
                            }
                          }
                          const data = await addQuestion({
                            variables: {
                              questionFormId: questionFormId ?? undefined,
                              name: formik.values.name,
                              requestedAmount: currency(formik.values.amount)
                                .intValue,
                              memo: formik.values.memo,
                              dueDateEpochMillis: dueDate
                                ? dueDate.getTime()
                                : undefined,
                              schema: JSON.stringify({
                                amountEditable: formik.values.amountEditable,
                              }),
                              documentBuffers:
                                formik.values.fileBuffer?.filter(
                                  (buffer): buffer is string =>
                                    typeof buffer === 'string' &&
                                    buffer.trim() !== '',
                                ) ?? [],
                              thumbnail:
                                ref.current?.toDataURL('image/png') ??
                                undefined,
                              status: QuestionFormStatus.Active,
                              invoiceId: formik.values.invoiceId,
                              projectId: projectId || undefined,
                              feePassthroughPercent: passthroughActive
                                ? formik.values.feePassthroughPercent
                                : undefined,
                            },
                          })

                          if (
                            !data.data?.addQuestionForm?.error &&
                            props.onClose
                          ) {
                            props.setSuccess && (await props.setSuccess(true))
                          }
                        },
                      }}
                    />
                  </HStack>
                </Box>
              </Column>
            </>
          )
        }}
      </Form>
    </>
  )
}

export default CreatePaymentLinkOverlay
