import { useQuery } from '@apollo/client'
import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Column, Row, Separator } from 'ui'
import { Vendor, VendorsDocument } from '../../operations-types'

type SlimSelectVendorProps = {
  selectedValue?: Vendor | null
  onVendorSelect: (vendor: Vendor | null) => void
  onNewVendor: (vendorName: string) => void
  label?: boolean
}

export function SlimSelectVendor({
  onVendorSelect,
  onNewVendor,
  selectedValue,
  label = true,
}: SlimSelectVendorProps) {
  const { refetch } = useQuery(VendorsDocument)

  useEffect(() => {
    const fetchSelectedVendor = async () => {
      if (selectedValue) {
        const selectedVendorData = await refetch({
          vendorName: selectedValue.name,
        })
        onVendorSelect(selectedVendorData.data?.vendors?.vendors?.[0] || null)
      }
    }
    fetchSelectedVendor()
  }, [selectedValue])

  const filterVendors = async (inputValue: string) => {
    const fetchedVendors = await refetch({
      vendorName: inputValue,
    })

    return (fetchedVendors.data?.vendors?.vendors || []).map(
      (vendor: Vendor) => ({
        value: vendor,
        label: vendor.name!,
      }),
    )
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: { label: string; value: Vendor }[]) => void,
  ) => {
    setTimeout(async () => {
      const data = await filterVendors(inputValue || selectedValue?.name || '')
      callback(data)
    }, 200)
  }

  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true)

  return (
    <Flex flexDirection="column" gap={4} className="w-full">
      <FormControl>
        {label && <FormLabel fontSize="md">Name</FormLabel>}
        <AsyncSelect
          loadOptions={loadOptions}
          defaultOptions
          menuIsOpen={menuIsOpen}
          onFocus={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          onMenuOpen={() => setMenuIsOpen(true)}
          onBlur={() => setMenuIsOpen(true)}
          openMenuOnClick={true}
          openMenuOnFocus={true}
          autoFocus
          value={
            selectedValue
              ? {
                  label: selectedValue.name || '',
                  value: selectedValue || null,
                }
              : undefined
          }
          inputValue={inputValue}
          placeholder="Type to search or add a new recipient"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: 'gray.200',
              boxShadow: 'none',
              '&:hover': {
                borderColor: 'gray.200',
              },
            }),
            noOptionsMessage: (base) => ({
              ...base,
              color: 'gray.800',
              cursor: 'pointer',
            }),
          }}
          components={{
            NoOptionsMessage: (props) =>
              inputValue !== undefined &&
              inputValue?.trim().length > 0 &&
              inputValue !== null ? (
                <div
                  onClick={() => {
                    onNewVendor(props.children as string)
                    setInputValue(props.children as string)
                    setMenuIsOpen(false)
                  }}
                >
                  <components.NoOptionsMessage {...props}>
                    <Box>
                      <Row grow x="left">
                        <Text>
                          + Add <b>{props.children}</b> as a new recipient
                        </Text>
                      </Row>
                    </Box>
                  </components.NoOptionsMessage>
                </div>
              ) : (
                <div />
              ),
            Option: (props) => (
              <Column gap="small">
                {props.innerProps.id?.includes('option-0') &&
                (inputValue?.trim().length === 0 ||
                  inputValue === undefined ||
                  inputValue === null) ? (
                  <Column grow y="center" x="left" className="pb-0" wGrow>
                    <Row grow spacing="small">
                      <Text className="text-uppercase text-sm text-gray-500">
                        Recent Recipients
                      </Text>
                    </Row>
                    <Separator orientation="horizontal" />
                  </Column>
                ) : (
                  ''
                )}
                <Row
                  grow
                  y="center"
                  x="left"
                  spacing="small"
                  className="cursor-pointer hover:bg-blue-100"
                  {...{
                    onClick: () => {
                      setInputValue(props.children as string)
                      setMenuIsOpen(false)
                      onVendorSelect(props.data.value)
                    },
                  }}
                >
                  {props.label}
                </Row>
              </Column>
            ),
          }}
          onInputChange={(value) => {
            setInputValue(value)
            setMenuIsOpen(true)
          }}
          noOptionsMessage={(inputValue) => inputValue.inputValue}
          onChange={(option) => {
            if (!option) {
              return
            }

            onVendorSelect(option.value)
            setMenuIsOpen(false)
          }}
        />
      </FormControl>
    </Flex>
  )
}
