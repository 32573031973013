import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Card,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Entity, EntityField } from 'ui'
import {
  ChartOfAccountsDocument,
  SelfDocument,
  UpdateOrCreateChartOfAccountDocument,
} from '../../operations-types'
import Loading from '../utils/Loading'

type AccountType = 'creditCard' | 'bankAccount'

type ChartOfAccountsProps = {
  onCompleted?: () => void
  type: AccountType
  paymentMethodId?: string
  payoutMethodId?: string
  accountString: string
}

type QBOChartOfAccountsModalProps = ChartOfAccountsProps & {
  isOpen: boolean
  setModalOpen: (open: boolean) => void
}

export function QBOChartOfAccountsModal(props: QBOChartOfAccountsModalProps) {
  const { isOpen, setModalOpen, ...rest } = props

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setModalOpen(false)
      }}
    >
      <ModalOverlay />
      <ModalContent w="100%">
        <div className="modal-wrapper">
          <QBOChartOfAccounts {...rest} />
        </div>
      </ModalContent>
    </Modal>
  )
}

export function QBOChartOfAccounts({
  onCompleted,
  type,
  paymentMethodId,
  payoutMethodId,
  accountString,
}: ChartOfAccountsProps) {
  const toaster = useToast()
  const { data } = useQuery(ChartOfAccountsDocument, {
    variables: {
      query:
        type === 'bankAccount'
          ? "where AccountType='Bank' and Classification='Asset' and Active=true"
          : "where AccountType='Credit Card' and Classification='Liability' and Active=true",
    },
    pollInterval: 1000,
  })

  const chartOfAccounts = data?.chartOfAccounts?.chartOfAccounts || []

  const [updateChartOfAccount, { loading }] = useMutation(
    UpdateOrCreateChartOfAccountDocument,
    {
      onCompleted: async (data) => {
        if (data.updateOrCreateChartOfAccount?.error) {
          toaster({
            status: 'error',
            title: data.updateOrCreateChartOfAccount?.error.message,
          })
          return
        }

        if (onCompleted) {
          toaster({
            status: 'success',
            title: 'Chart of Account updated successfully',
          })
          onCompleted()
        }
      },
      refetchQueries: [SelfDocument],
    },
  )

  return (
    <Card p={8}>
      <Flex flexDirection="column" gap={6}>
        <Flex flexDirection="column" gap={2}>
          <Text fontSize="2xl" fontWeight="semibold">
            Chart of Accounts
          </Text>
          <Box>
            <Text color="gray.500" fontSize="sm" as="span">
              Pick an account from your chart of accounts in your accounting
              software to link to {accountString}.{' '}
            </Text>
            <Text fontWeight="semibold" fontSize="sm" as="span">
              If you do not see your account, you must first create it in your
              accounting software.
            </Text>
          </Box>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          {(loading || chartOfAccounts.length === 0) && <Loading />}
          {!loading &&
            chartOfAccounts.map((account) => (
              <Entity
                key={account.id}
                onClick={async () => {
                  await updateChartOfAccount({
                    variables: {
                      chartOfAccountId: account.id,
                      paymentMethodId: paymentMethodId,
                      payoutMethodId: payoutMethodId,
                    },
                  })
                }}
              >
                <EntityField title={account.name || ''} />
              </Entity>
            ))}
        </Flex>
      </Flex>
    </Card>
  )
}
