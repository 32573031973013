import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, useLocation } from 'react-router-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { ChakraThemeProvider } from 'ui'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

if (typeof window !== 'undefined' && import.meta.env.VITE_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST || 'https://app.posthog.com',
    loaded: () => {
      if (import.meta.env.MODE === 'development') {
        console.log('Posthog loaded')
      }
    },
    session_recording: {
      maskAllInputs: false,
    },
    capture_pageview: false,
  })
}

export default function PostHogPageView() {
  let location = useLocation()

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      })
    }
  }, [location])

  return null
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const AppComponent = () => (
  <React.StrictMode>
    <BrowserRouter>
      <PostHogProvider client={posthog}>
        <ChakraProvider
          {...{
            theme: ChakraThemeProvider(),
            resetCSS: true,
          }}
        >
          <App />
          <PostHogPageView />
        </ChakraProvider>
      </PostHogProvider>
    </BrowserRouter>
  </React.StrictMode>
)

root.render(<AppComponent />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
