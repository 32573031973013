import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Icon,
  useClipboard,
  InputGroupProps,
} from '@chakra-ui/react'

import { FiCopy } from 'react-icons/fi'
export type CopyInputProps = {
  clipboardValue: string
} & InputGroupProps

export function CopyInput({ clipboardValue, ...other }: CopyInputProps) {
  const { onCopy, value, hasCopied } = useClipboard(clipboardValue)

  return (
    <HStack w="100%">
      <InputGroup {...other} borderRadius="4px">
        <Input
          value={value}
          isReadOnly
          color="gray.600"
          cursor="pointer"
          bgColor="gray.100"
          onClick={onCopy}
          _hover={{}}
        />
        <InputRightAddon
          border="none"
          ps="4"
          color="gray.500"
          bgColor="gray.100"
          fontSize="10px"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onCopy()
          }}
          _hover={{
            cursor: 'pointer',
            bgColor: 'gray.200',
          }}
        >
          <Box pe="1">
            <Icon as={FiCopy} boxSize="4" me="1" color="gray.500" />
          </Box>
          <Text color="gray.500" fontSize="13px">
            {hasCopied ? 'Copied!' : 'COPY'}
          </Text>
        </InputRightAddon>
      </InputGroup>
    </HStack>
  )
}
