import LoggedInLayout from './layout/LoggedInLayout'
import { Navigate, createSearchParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useAuth } from '../lib/auth'
import Loading from './utils/Loading'
import { SelfDocument, User } from '../operations-types'
import posthog from 'posthog-js'
import { FullscreenLoggedInLayout } from './FullScreenLoggedInLayout'
import { useLoggedInStore } from './layout/LoggedInStore'
import Intercom from '@intercom/messenger-js-sdk'

export default function LoggedIn() {
  const { setUser } = useLoggedInStore()
  const { loading, data } = useQuery(SelfDocument, {
    onCompleted: (data) => {
      if (data.user?.user) {
        setUser(data.user.user)
      }
    },
  })
  const location = useLocation()

  const { signOut, getAdminUserId, getAccountantUserId } = useAuth()
  const organization = data?.user?.user?.organization

  if (loading) {
    return <Loading additionalClasses={{ 'h-screen': true }} />
  }

  const user = data?.user
  const accountant = user?.user?.organization?.accountant?.user
  if (organization) {
    posthog.group('organization', organization.id, {
      name: organization.name,
      id: organization.id,
    })
  }

  if (!user || user.error?.type || !user.user || !user.user.organization?.id) {
    signOut()

    if (location) {
      return (
        <Navigate
          to={{
            pathname: '/login',
            search: createSearchParams({
              redirect: location.pathname,
            }).toString(),
          }}
        />
      )
    }
    return <Navigate to={{ pathname: '/login' }} />
  }

  const adminUserId = getAdminUserId()
  const accountantUserId = getAccountantUserId()
  const getName = (user?: Pick<User, 'firstName' | 'lastName'> | null) =>
    `${user?.firstName} ${user?.lastName}`
  const getCreatedAt = (user?: Pick<User, 'createdAt'> | null) =>
    Math.floor(parseInt(user?.createdAt || '') / 1000)

  if (
    import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production' &&
    !adminUserId
  ) {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      email: accountantUserId
        ? accountant?.email || ''
        : data?.user?.user?.email || '',
      user_id: accountantUserId ? accountant?.id : data?.user?.user?.id,
      name: accountantUserId ? getName(accountant) : getName(data?.user?.user),
      created_at: accountantUserId
        ? getCreatedAt(accountant)
        : getCreatedAt(data?.user?.user),
    })
  }

  return <LoggedInLayout loggedInUser={user.user} />
}

export function FullscreenLoggedIn() {
  const { setUser } = useLoggedInStore()

  const { loading, data } = useQuery(SelfDocument, {
    onCompleted: (data) => {
      if (data.user?.user) {
        setUser(data.user.user)
      }
    },
  })

  if (loading) {
    return <Loading additionalClasses={{ height: '100vh' }} />
  }

  const user = data?.user

  if (!user || user.error?.type || !user.user) {
    return <Navigate to="/login" />
  }

  return <FullscreenLoggedInLayout loggedInUser={user.user} />
}
