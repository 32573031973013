import { Line } from 'react-chartjs-2'
import {
  Title as TextTitle,
  Card,
  UnstandardizedRow,
  Column,
  TitleSubheader,
} from 'ui'
import { TableCellButton } from 'ui/src/components/Layout/Table/CellButton'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import { useQuery } from '@apollo/client'
import { GetPaymentHistoryDocument } from '../../operations-types'
import moment from 'moment'
import formatter from 'ui/src/formatter'

export default function DashboardChart() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  )

  const { loading, data: paymentData } = useQuery(GetPaymentHistoryDocument)
  const historyData = paymentData?.paymentHistory?.payments || []

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const data = {
    labels: historyData.map((payment) =>
      moment(parseInt(payment.date)).format('MM/DD/YY'),
    ),
    datasets: [
      {
        label: 'Payments Received',
        data: historyData.map((payment) => payment.totalSubmittedCents / 100),
        fill: true,
        fillColor: 'rgb(224, 217, 227)',
        strokeColor: 'rgb(224, 217, 227)',
        borderColor: 'rgb(104, 93, 109)',
      },
    ],
  }

  const options = {
    aspectRatio: 4.4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return formatter.format(tooltipItem.raw)
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value: any, _index: any, _values: any) {
            return formatter.format(value)
          },
        },
      },
    },
  }

  const totalDollars =
    historyData
      .map((payment) => payment.totalSubmittedCents)
      .reduce((a, b) => a + b, 0) / 100

  return (
    <Card className="p-4 flex flex-col grow">
      <Column gap="large">
        <UnstandardizedRow grow between>
          <Column gap="small">
            <TextTitle variant="secondary">Payments Received</TextTitle>
            <TitleSubheader>{formatter.format(totalDollars)}</TitleSubheader>
          </Column>
          <Column>
            <TableCellButton>Last 30 Days</TableCellButton>
          </Column>
        </UnstandardizedRow>
        <Line data={data} options={options} />
      </Column>
    </Card>
  )
}
